import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'


// main.ts 
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//import '@/styles/element/index.scss'
import "@/common/css/common.scss"
import '@/common/css/normalize.css'

// import VMdEditor from '@kangc/v-md-editor';
// import '@kangc/v-md-editor/lib/style/base-editor.css';
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
// import '@kangc/v-md-editor/lib/theme/style/github.css';
// VMdEditor.use(githubTheme)
// app.use(VMdEditor);
// @ts-ignore
import {zhCn} from "@/util/zhCn"
import "@/util/tinyMce"

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import mitt from 'mitt';

// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import  Editor  from '@tinymce/tinymce-vue'
const app = createApp(App)
import tinymce from 'tinymce'
app.config.globalProperties.$tinymce = tinymce

app.component('Editor', Editor)

const emitter = mitt();

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.provide('emitter', emitter);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
//在整个项目中引入pinia

app.config.warnHandler = () => null;
app.use(router)
app.use(ElementPlus,{
    locale:zhCn,
})
app.mount('#app')
//路由跳转顶部
router.afterEach((to, from, next) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });


