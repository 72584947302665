import request from '../util/request';


//我的主页
export const homepage = () => {
    return request({
        url: '/rent/message/homepage',
        method: 'get',
    })
};

//黑名单列表
export const getBlacklistVoPageListByLoginCustId = (params: any) => {
    return request({
        url: `/rent/blacklist/getBlacklistVoPageListByLoginCustId`,
        method: 'get',
        params
    })
}

//初始黑名单
export const getFirstBidBlacklist = (params: any) => {
    return request({
        url: `/rent/blacklist/getFirstBidBlacklist`,
        method: 'get',
        params
    })
}


//新增黑名单
export const addCustBlacklist = (data: any) => {
    return request({
        url: `/rent/blacklist/addCustBlacklist`,
        method: 'post',
        data
    })
}

//转黑名单
export const updateEnableStatus = (data: any) => {
    return request({
        url: `/rent/blacklist/updateEnableStatus/`+data.blacklistId,
        method: 'put',
        data
    })
}


//取消黑名单
export const updateCancelStatus = (data: any) => {
    return request({
        url: `/rent/blacklist/updateCancelStatus/`+data.blacklistId,
        method: 'put',
        data
    })
}

//添加优先人
export const addPriority = (data: any) => {
    return request({
        url: `/rent/priority`,
        method: 'post',
        data
    })
}

//取消优先人
export const delPriority = (data: any) => {
    return request({
        url: `/rent/priority/deleteByPriorityIds/`+data.priorityIds,
        method: 'put',
        data
    })
}


//经办人授权列表
export const getOperatorVoPageListByLoginCustId = (params: any) => {
    return request({
        url: `/rent/operator/getOperatorVoPageListByLoginCustId`,
        method: 'get',
        params
    })
}


//修改经办人状态为停用
export const updateOperator = (data: any) => {
    return request({
        url: `/rent/operator/updateCancelStatus/`+data.operator_id,
        method: 'put',
        data
    })
}

//修改经办人状态为启用
export const updateEnableStatus_operator = (data: any) => {
    return request({
        url: `/rent/operator/updateEnableStatus/`+data.operator_id,
        method: 'put',
        data
    })
}

//查看经办人
export const getOperatorVoByOperatorId = (params: any) => {
    return request({
        url: `/rent/operator/getOperatorVoByOperatorId/`+params.operator_id,
        method: 'get',
        params
    })
}

//新增修改经办人
export const operator_push = (data: any) => {
    return request({
        url: `/rent/operator`,
        method: 'post',
        data
    })
}





//修改客户信息(联系地址和联系邮箱)
export const updateCustAddressAndEmail = (data: any) => {
    return request({
        url: `/rent/cust/updateCustAddressAndEmail`,
        method: 'put',
        data
    })
}

//修改手机
export const updateCustPhonenumber = (data: any) => {
    return request({
        url: `/rent/cust/updateCustPhonenumber`,
        method: 'put',
        data
    })
}

//修改密码
export const updateCustPassword = (data: any) => {
    return request({
        url: `/rent/cust/updateCustPassword`,
        method: 'put',
        data
    })
}

//查看所有疑问
export const getansList = (params: any) => {
    return request({
        url: `/rent/bidQuestion`,
        method: 'get',
        params
    })
}

//查看需要回复的问题
export const replyQuestionList = (params: any) => {
    return request({
        url: `/rent/bidQuestion/replyQuestionList`,
        method: 'get',
        params
    })
}

//添加疑问
export const bidQuestion_create = (data: any) => {
    return request({
        url: `/rent/bidQuestion/create`,
        method: 'post',
        data
    })
}

//回复疑问
export const bidQuestion_reply = (data: any) => {
    return request({
        url: `/rent/bidQuestion/reply`,
        method: 'put',
        data
    })
}

//撤销疑问
export const bidQuestion_revoke = (params: any) => {
    return request({
        url: `/rent/bidQuestion/revoke`,
        method: 'DELETE',
        params
    })
}



//删除回复
export const  getanstDel = (data: any) => {
    return request({
        url: `/rent/bidQuestion/revoke`,
        method: 'DELETE',
        data
    })
}


//未结/已结订单
export const getBidVoPageListByLoginCustId = (params: any) => {
    return request({
        url: '/rent/bid/getBidVoPageListByLoginCustId',
        method: 'get',
        params
    })
};

// 获取带有成交公告的未结/已结订单
export const getNotice = (params: any) => {
    return request({
        url: '/rent/bid/notice',
        method: 'get',
        params
    })
};

//竞租方分页查询答疑
export const getTenantQcPageList = (params: any) => {
    return request({
        url: '/rent/bidQuestion/getTenantQcPageList',
        method: 'get',
        params
    })
};

//删除黑名单
export const delBlacklistIds = (params: any) => {
    return request({
        url: '/rent/blacklist/deleteFromBid/'+params.blacklistIds,
        method: 'DELETE',
        params
    })
};

//删除优先人
export const delPriorityIds = (params: any) => {
    return request({
        url: '/rent/priority/deleteByPriorityIds/'+params.priorityIds,
        method: 'DELETE',
        params
    })
};


// 客服
export const customerReply = (params: any) => {
    return request({
        url: '/rent/consultations/writeBack',
        method: 'get',
        params
    })
};

///标的变更列表
export const getApplyErrorBidVoPageList = (params: any) => {
    return request({
        url: '/rent/bid/getApplyErrorBidVoPageList',
        method: 'get',
        params
    })
};

//我的出租列表
export const getMyRentBidVoPageList = (params: any) => {
    return request({
        url: '/rent/bid/getMyRentBidVoPageList',
        method: 'get',
        params
    })
};


//标的撤回
export const recallBid = (data: any) => {
    return request({
        url: '/rent/bid/recallBid',
        method: 'put',
        data
    })
};

//删除资产
export const delassets = (params: any) => {
    return request({
        url: '/rent/assets/'+params.assetsIds        ,
        method: 'DELETE',
        params
    })
};
