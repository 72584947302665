<template>
  <body>
    <my-header ref="headEl" v-if="CApath"/>
    <main>
      <router-view v-if="isRouterAlive"/>
    </main>
    <my-footer v-if="CApath" ref="layoutFooter"/>
  </body>
</template>
<script setup lang="ts">
import MyFooter from "@/components/MyFooter.vue"
import MyHeader from "@/components/MyHeader.vue"
import { useRouter} from 'vue-router'
import { onMounted, ref, provide, nextTick, watch  } from 'vue'
const isRouterAlive = ref(true)

let acIndex = ref(0)
 const reload=()=>{
  isRouterAlive.value=false
  nextTick(()=>{
    isRouterAlive.value=true
  })
 }
 const headEl:any = ref(null)
 const CApath:any = ref(true)
 const router = useRouter()
  watch(() => router.currentRoute.value,(router: any) => {
    if(router.path == '/sealNew' || router.path == '/bidsPrint'){
      CApath.value=false;
    }
		if (router.path == '/signup') {
			headEl.value.setActiveIndex(2)
		}
    else if(router.name=="Mydetails"){
      headEl.value.setActiveIndex(1)
    }
    else if(router.name=="CompetitionView"){
      headEl.value.setActiveIndex(1)
    }
    else if(router.path=="/Transactiondetails"){
      headEl.value.setActiveIndex(2)
    }
    else if(router.path=="/myPageHome"){
      headEl.value.setActiveIndex(null)
    }else if((router.path=="/")){
      headEl.value.setActiveIndex(0)
    }else if(router.path=="/biddingHall"){
      headEl.value.setActiveIndex(3)
    }else if(router.path=="/consultationhelp"){
      headEl.value.setActiveIndex(4)
    }else if(router.path=="/appoint"){
      headEl.value.setActiveIndex(5)
    }else if(router.path=="/about"){
      headEl.value.setActiveIndex(6)
    }
    else{
      headEl.value.setActiveIndex(null)
    }
  })
  provide('reload', reload)
	onMounted(() => {
		document.body.style.setProperty('--el-color-primary', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-1', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-2', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-3', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-4', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-5', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-6', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-7', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-8', '#C08C58');
		document.body.style.setProperty('--el-color-primary-dark-2', '#C08C58');
	})


</script>
<style lang="scss">
#nprogress .bar {
  background: #F0A517 !important; //自定义颜色
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
body {
  // font: 16px "宋体", "Arial Narrow", HELVETICA;
  background: #FEFAF6;
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
a {
  color: #2d374b;
  text-decoration: none;
}
a:hover {
  color: #cd0200;
  text-decoration: underline;
}
em {
  font-style: normal;
}
li {
  list-style: none;
}
img {
  border: 0;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
p {
  word-wrap: break-word;
}
.messageBoxTitle {
  .el-message-box__header.show-close {
    padding-right: 0;
  }
}

@media print{
  @page {
    size:auto;/*使用打印纸张的大小，默认是auto*/
    margin:3mm;/*打印页面边缘设置为0*/

  }/*去除页眉*/
}

.el-message-box.myCustomClass{
  max-width: 50%;
}
</style>
