import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/views/images/logos.png'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "warp" }
const _hoisted_3 = { class: "logo" }
const _hoisted_4 = { class: "rightBar" }
const _hoisted_5 = { class: "rightIcon" }
const _hoisted_6 = { class: "rightIcon" }
const _hoisted_7 = { class: "message iconRow" }
const _hoisted_8 = { class: "msgList" }
const _hoisted_9 = { class: "msgHead" }
const _hoisted_10 = { class: "msgCon" }
const _hoisted_11 = { class: "info" }
const _hoisted_12 = { class: "title" }
const _hoisted_13 = { class: "subTitle" }
const _hoisted_14 = { class: "msgCon" }
const _hoisted_15 = { class: "info" }
const _hoisted_16 = { class: "title" }
const _hoisted_17 = { class: "subTitle" }
const _hoisted_18 = { class: "header-title" }
const _hoisted_19 = { class: "header-titles" }
const _hoisted_20 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserFilled = _resolveComponent("UserFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Bell = _resolveComponent("Bell")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_View = _resolveComponent("View")!
  const _component_SwitchButton = _resolveComponent("SwitchButton")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _imports_0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toPath(_ctx.navs[0]))),
          alt: "",
          class: "logo"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: "login iconRow",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.routePush('/login', 'login')))
          }, [
            _createVNode(_component_el_icon, {
              color: "#c08c58",
              size: "20"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UserFilled)
              ]),
              _: 1
            }),
            _cache[11] || (_cache[11] = _createElementVNode("text", null, "登录", -1))
          ]),
          _createElementVNode("div", {
            class: "regist iconRow",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.routePush('/login', 'zhuce')))
          }, [
            _createVNode(_component_el_icon, {
              color: "#c08c58",
              size: "20"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Avatar)
              ]),
              _: 1
            }),
            _cache[12] || (_cache[12] = _createElementVNode("text", null, "注册", -1))
          ])
        ], 512), [
          [_vShow, !_ctx.isLogin]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.messageData.msgNum + _ctx.messageData.toDoNum)
              ? (_openBlock(), _createBlock(_component_el_badge, {
                  key: 0,
                  value: _ctx.messageData.msgNum + _ctx.messageData.toDoNum,
                  class: "item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, {
                      color: "#c08c58",
                      size: "20",
                      style: {"margin-top":"10px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Bell)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"]))
              : (_openBlock(), _createBlock(_component_el_badge, {
                  key: 1,
                  class: "item"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, {
                      color: "#c08c58",
                      size: "20",
                      style: {"margin-top":"10px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Bell)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })),
            _cache[20] || (_cache[20] = _createTextVNode(" 消息 ")),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("a", {
                  class: _normalizeClass(["headItem", { active: _ctx.messageData.msgType === 0 }]),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handlerTabs(0)))
                }, [
                  _cache[13] || (_cache[13] = _createTextVNode(" 待办")),
                  _createVNode(_component_el_badge, {
                    value: _ctx.messageData.toDoNum ? _ctx.messageData.toDoNum : 0,
                    class: "item"
                  }, null, 8, ["value"])
                ], 2),
                _createElementVNode("a", {
                  class: _normalizeClass(["headItem", { active: _ctx.messageData.msgType === 1 }]),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handlerTabs(1)))
                }, [
                  _cache[14] || (_cache[14] = _createTextVNode(" 消息")),
                  _createVNode(_component_el_badge, {
                    value: _ctx.messageData.msgNum ? _ctx.messageData.msgNum : 0,
                    class: "item"
                  }, null, 8, ["value"])
                ], 2)
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageData.toDoList, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "item",
                    key: item.scheduleConnectedId,
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goPage('/toDo')))
                  }, [
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "icon" }, null, -1)),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _cache[15] || (_cache[15] = _createTextVNode(" 您的标的")),
                        _createElementVNode("span", null, _toDisplayString(item.scheduleName), 1),
                        _createTextVNode("待" + _toDisplayString(item.scheduleTypeName), 1)
                      ]),
                      _createElementVNode("div", _hoisted_13, _toDisplayString(item.scheduleTime), 1)
                    ])
                  ]))
                }), 128)),
                _createElementVNode("div", {
                  class: "msgFoot",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goPage('/toDo')))
                }, [
                  _createVNode(_component_el_icon, { class: "mr5" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_View)
                    ]),
                    _: 1
                  }),
                  _cache[17] || (_cache[17] = _createTextVNode("查看更多"))
                ])
              ], 512), [
                [_vShow, _ctx.messageData.msgType === 0]
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageData.msgList, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "item",
                    key: item.dmessageIds,
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.goPage('/myMsg')))
                  }, [
                    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "icon" }, null, -1)),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, _toDisplayString(item.message), 1),
                      _createElementVNode("div", _hoisted_17, _toDisplayString(item.createTime), 1)
                    ])
                  ]))
                }), 128)),
                _createElementVNode("div", {
                  class: "msgFoot",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.goPage('/myMsg')))
                }, [
                  _createVNode(_component_el_icon, { class: "mr5" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_View)
                    ]),
                    _: 1
                  }),
                  _cache[19] || (_cache[19] = _createTextVNode("查看更多"))
                ])
              ], 512), [
                [_vShow, _ctx.messageData.msgType === 1]
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "user iconRow",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.routePush('/myPageHome', 'myhome')))
          }, [
            _createVNode(_component_el_icon, {
              color: "#c08c58",
              size: "20"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Avatar)
              ]),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.user.username), 1)
          ]),
          _createElementVNode("div", {
            class: "loginOut iconRow",
            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)))
          }, [
            _createVNode(_component_el_icon, {
              class: "exit",
              color: "#c08c58",
              size: "20"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SwitchButton)
              ]),
              _: 1
            }),
            _cache[21] || (_cache[21] = _createTextVNode(" 退出 "))
          ])
        ], 512), [
          [_vShow, _ctx.isLogin]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navs, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            onClick: ($event: any) => (_ctx.toPath(item)),
            key: index,
            class: _normalizeClass(["item shouzhi", _ctx.activeIndex === index ? 'item-on' : ''])
          }, _toDisplayString(item.title), 11, _hoisted_20))
        }), 128))
      ])
    ])
  ]))
}