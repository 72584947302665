import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef } from "vue"
import _imports_0 from '@/assets/image/icon_Bid.png'
import _imports_1 from '@/assets/image/icon_Released.png'
import _imports_2 from '@/assets/image/icon_Cumulative.png'
import _imports_3 from '@/assets/image/icon_Bid2.png'
import _imports_4 from '@/views/images/img_logo1@2x.png'
import _imports_5 from '@/views/images/img_logo2@2x.png'


const _hoisted_1 = { class: "home flex-column align-center" }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { class: "homes" }
const _hoisted_4 = {
  class: "lightgreen-box bc-fff",
  style: {"box-shadow":"2px 3px 6px 1px rgba(0, 0, 0, 0.08)"}
}
const _hoisted_5 = { class: "home-content" }
const _hoisted_6 = { class: "content-title 8jm," }
const _hoisted_7 = {
  class: "flex-column rela shouzh",
  style: {"width":"49%"}
}
const _hoisted_8 = { class: "fs20 lh30 tx-big $c-333" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 2,
  style: {"height":"100%"}
}
const _hoisted_11 = {
  class: "orange-box bc-fff",
  style: {"box-shadow":"2px 3px 6px 1px rgba(0, 0, 0, 0.08)","height":"100%"}
}
const _hoisted_12 = { class: "home-content" }
const _hoisted_13 = { class: "content-title" }
const _hoisted_14 = {
  class: "align-center",
  style: {"flex":"1","flex-wrap":"nowrap","text-overflow":"ellipsis","width":"0"}
}
const _hoisted_15 = {
  key: 0,
  class: "bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { style: {"margin-left":"20px"} }
const _hoisted_18 = {
  key: 2,
  style: {"height":"100%"}
}
const _hoisted_19 = { class: "homes" }
const _hoisted_20 = {
  class: "bc-fff",
  style: {"box-shadow":"2px 3px 6px 1px rgba(0, 0, 0, 0.08)"}
}
const _hoisted_21 = { class: "home-content home-image" }
const _hoisted_22 = { class: "mt15 mb15" }
const _hoisted_23 = { class: "home-image-num fs32" }
const _hoisted_24 = { class: "home-image-num fs32" }
const _hoisted_25 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_26 = { class: "home-image-num fs32" }
const _hoisted_27 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_28 = { class: "home-image-num fs32" }
const _hoisted_29 = { class: "homes" }
const _hoisted_30 = {
  class: "bc-fff",
  style: {"box-shadow":"2px 3px 6px 1px rgba(0, 0, 0, 0.08)"}
}
const _hoisted_31 = { class: "home-content" }
const _hoisted_32 = {
  id: "target",
  style: {"display":"flex","height":"100%","justify-content":"space-around","width":"1400px","flex-wrap":"wrap"}
}
const _hoisted_33 = { style: {"display":"flex","width":"1350px","flex-wrap":"wrap","margin-right":"32px"} }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { class: "bg-c lh-180" }
const _hoisted_36 = ["src"]
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { class: "rent-content-price" }
const _hoisted_39 = { class: "rent-content-price1 ml20 mt5" }
const _hoisted_40 = { class: "rent-content-price2 shouzhi mr20 mt5" }
const _hoisted_41 = { class: "mt10" }
const _hoisted_42 = {
  class: "ml20",
  style: {"color":"#65686d"}
}
const _hoisted_43 = {
  class: "mt10 ml20",
  style: {"color":"#65686d"}
}
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { class: "mt20 tx-c" }
const _hoisted_46 = {
  key: 0,
  style: {"color":"#3d4a7f"}
}
const _hoisted_47 = {
  key: 1,
  style: {"color":"#3d4a7f"}
}
const _hoisted_48 = { class: "homes" }
const _hoisted_49 = { style: {"box-shadow":"2px 3px 6px 1px rgba(0, 0, 0, 0.08)","background":"#ffffff","height":"335px"} }
const _hoisted_50 = { class: "home-content" }
const _hoisted_51 = { class: "content-title" }
const _hoisted_52 = { key: 0 }
const _hoisted_53 = { class: "align-center" }
const _hoisted_54 = {
  key: 0,
  class: "bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
}
const _hoisted_55 = ["onClick"]
const _hoisted_56 = {
  key: 1,
  style: {"height":"100%"}
}
const _hoisted_57 = { key: 0 }
const _hoisted_58 = { class: "align-center" }
const _hoisted_59 = {
  key: 0,
  class: "bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
}
const _hoisted_60 = ["onClick"]
const _hoisted_61 = {
  key: 1,
  style: {"height":"100px"}
}
const _hoisted_62 = { key: 0 }
const _hoisted_63 = { class: "align-center" }
const _hoisted_64 = {
  key: 0,
  class: "bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
}
const _hoisted_65 = ["onClick"]
const _hoisted_66 = {
  key: 1,
  style: {"height":"100%"}
}
const _hoisted_67 = { style: {"box-shadow":"2px 3px 6px 1px rgba(0, 0, 0, 0.08)","background":"#ffffff","height":"100%"} }
const _hoisted_68 = { class: "home-content" }
const _hoisted_69 = { class: "content-title" }
const _hoisted_70 = { key: 0 }
const _hoisted_71 = { class: "align-center" }
const _hoisted_72 = {
  key: 0,
  class: "bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
}
const _hoisted_73 = ["onClick"]
const _hoisted_74 = {
  key: 1,
  style: {"height":"100%"}
}
const _hoisted_75 = { class: "homes" }
const _hoisted_76 = { style: {"box-shadow":"2px 3px 6px 1px rgba(0, 0, 0, 0.08)","background":"#ffffff"} }
const _hoisted_77 = { class: "home-contentb" }
const _hoisted_78 = { class: "signup" }
const _hoisted_79 = { class: "home-contenta" }
const _hoisted_80 = { class: "lwbox" }
const _hoisted_81 = ["onClick"]
const _hoisted_82 = {
  id: "target1",
  class: "wrap plr20"
}
const _hoisted_83 = {
  key: 0,
  class: "wrap"
}
const _hoisted_84 = { class: "riliList wrap flex-between" }
const _hoisted_85 = ["onClick"]
const _hoisted_86 = { class: "bg-c lh-180 lw-200" }
const _hoisted_87 = ["src"]
const _hoisted_88 = {
  class: "mt5 ml10 mr5",
  style: {"flex-direction":"column"}
}
const _hoisted_89 = { class: "fs19 mt5 tx-big el-2" }
const _hoisted_90 = { class: "mt5 c-999" }
const _hoisted_91 = { class: "mt7 c-999" }
const _hoisted_92 = { class: "mt10 c-999" }
const _hoisted_93 = { class: "mt10 lwbox" }
const _hoisted_94 = {
  class: "c-c08 lh-30 fs12 lw-100 uc-3",
  style: {"border":"1px solid #c08c58"}
}
const _hoisted_95 = { class: "pagination" }
const _hoisted_96 = { class: "mb20 mt20" }
const _hoisted_97 = {
  key: 1,
  class: "mt20 tx-c flex-center"
}
const _hoisted_98 = { class: "dahezi bg-c mt12" }
const _hoisted_99 = { class: "homes" }
const _hoisted_100 = {
  class: "mb60 bc-fff",
  style: {"box-shadow":"2px 3px 6px 1px rgba(0, 0, 0, 0.08)"}
}
const _hoisted_101 = { class: "table-container mt20" }
const _hoisted_102 = { class: "bidding-wrapper" }
const _hoisted_103 = { class: "cell-value" }
const _hoisted_104 = ["onClick"]
const _hoisted_105 = { class: "cell-value" }
const _hoisted_106 = { class: "cell-value" }
const _hoisted_107 = { class: "cell-value" }
const _hoisted_108 = { class: "cell-value" }
const _hoisted_109 = { class: "cell-value" }
const _hoisted_110 = { class: "tableBtn" }
const _hoisted_111 = { class: "homes" }

import { onMounted, reactive, ref, watch, toRefs, nextTick, onUnmounted } from "vue";
import { BiCom, queryDict, getNonLoginPageList } from "@/api/competition";
import { ElMessage, ElMessageBox } from "element-plus";
import FilterBar from "@/components/FilterBar";
import Banner from "@/components/Banner";
import { homeList, getCalDetailedList } from "@/api/home";
import { getCalList } from "@/api/test";
import { getTokenStatus } from "@/api/competition";
import { useRoute, useRouter } from "vue-router";
import dataFormat from "dateformat";
import FixedMenu from "@/components/fixedMenu";
import botUI from "@/components/botUI";
import { parseTime } from '@/util/utils'

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props, { expose: __expose }) {

let route = useRoute();
let router = useRouter();
const listWrap = ref<HTMLElement>()

let screenListIndex = reactive([0, 0, 0, 0, 0, 0]);
let calendarvalue = ref(dataFormat(new Date(), "yyyy-mm-dd"));
let notice_ids = reactive({
  notice_id: 1,
});
//交易日历 默认分页数
const pageSize = ref(4);
//列表数据
let iconss: any = reactive({
  loading: true,
  bannerList: [],
  importanceNotice: {},
  sj: [],
  NoTi: [],
  NoId: [],
  icon: [],
  ErNo: [],
  Icon: [],
  RenNo: [],
  Title: [],
  Notice: [],
  aajinjia: [],
  data: {},
  region: "",
  bidType: "",
  themeQuery: "",
  bidTimeQuery: "",
  rentTypeQuery: "",
  bidPriceQuery: "",
  hemeQueryArray: [],
  rentTypeQueryArray: [],
  bidTypeArray: [],
  cityArray:[],
  regionArray: [],
  total: 1,
  current: 1,
  zhankai: true,
  rili: [],
  rili_total: "",
  sizes: [4],
  //日历周期
  dataArray: [
    new Date(new Date().getTime()),
    new Date(new Date().getTime() + 604800000),
  ],
  //月视图
  dataArray2: [
    new Date(new Date().getTime()),
    new Date(new Date().getTime() + 2626560000),
  ],
  calendarData: [],
  //当前选中的是哪一天的日历
  calendarDay: "",
});
const calendarPage = ref(1);
const calendarPageSize = ref(4);
let calendarDay: string | null = null;
onMounted(() => {
  getNonLoginPageListFN();
  bicomfn();
  homeListFN();
  queryDictFN();
  getCalListFN(dataFormat(new Date(), "yyyy-mm-dd hh:mm:ss"));
  calendarDay = dataFormat(new Date(), "yyyy-mm-dd hh:mm:ss");
  getCalDetailedListFN(dataFormat(new Date(), "yyyy-mm-dd hh:mm:ss"));
  //用来判断tonken是否失效
  getTokenStatus({}).then((res: any) => {});
});
let scrolltimer = null
onUnmounted(() => {
  clearInterval(scrolltimer)
})
const homeListFN = async () => {
  homeList().then((res) => {
    if (res.code == 200) {
      iconss.loading = false;
      // console.log("res.data:", res.data);
      // 新闻详情
      // console.log(res);

      if (res.data.news != null) {
        iconNews.iconN = res.data.news.title;
        iconNews.iconNo = res.data.news.details;
        iconNews.iconNoId = res.data.news.newsIds;
        iconNews.iconIMG.push(res.data.news.url);
      }
      //iconNews.iconIMG = res.data.news[0].imgUrl

      // 平台通知
      if (res.data.otherNoticeList.length) {
        iconss.Notice = res.data.otherNoticeList;
      }
      if (res.data.otherNoticeList.length) {
        iconss.NoId = res.data.otherNoticeList[0].noticeId;
      }

      //重要公告
      if (
        res.data.importanceNotice && res.data.importanceNotice.noticeContent &&
        res.data.importanceNotice.noticeContent.length
      ) {
        iconss.importanceNotice.noticeContent =
          res.data.importanceNotice.noticeContent;
        if (window.sessionStorage.getItem("isread") == "true") {
          // console.log(window.sessionStorage.getItem("isread"))
        } else {
          ElMessageBox.alert(
            iconss.importanceNotice.noticeContent,
            res.data.importanceNotice.noticeTitle,
            {
              dangerouslyUseHTMLString: true,
              center: true,
              customClass: 'myCustomClass',
              callback: () => {
                window.sessionStorage.setItem("isread", "true");
              },
            }
          );
        }
      }

      //成交公告
      iconss.NoTi = res.data.winNoticeList;
      //变更公告
      if (res.data && res.data.changeNoticeList) {
        iconss.RenNo = res.data.changeNoticeList;
      }
      //其他公告
      iconss.ErNo = res.data.errorNoticeList;
      //商机无限
      iconss.sj = res.data.advertisement;
      //成交数据
      iconss.data = res.data;
    }
  });
};
const clearAll = () => {
  let arr: any = [];
  let id = 1;
  data.screenList2.forEach((e: any) => {
    if (e.dictData.length) {
      e.dictData.forEach((v: any, i: number) => {
        id++;
        v.id = id;
        if (i == 0) {
          v.checked = true;
        } else {
          v.checked = false;
        }
      });
    }
    if (
      e.type == "bidTypeArray" ||
      e.type == "cityArray" ||
      e.type == "regionArray" ||
      e.type == "bidPriceQuery" ||
      e.type == "rentTypeQueryArray" ||
      e.type == "themeQueryArray" ||
      e.type == "bidTimeQuery"
    ) {
      arr.push(e);
    }
  });
  data.screenList2 = arr;
};
const dayClick = (item: any) => {
  // console.log(item);
  calendarDay = item.day + " 00:00:00";
  calendarPage.value = 1;
  let day = item.day + " 00:00:00";
  //iconss.calendarData = day;
  getCalDetailedListFN(day);
};
const currentPage = (e) => {
  // console.log(e)
  calendarPage.value = e;
  let day = iconss.calendarData;
  // console.log(day);
  getCalDetailedListFN(calendarDay);
};
//交易日历
const getCalListFN = (date: any) => {
  getCalList({
    date: date,
  }).then((res) => {
    if (res.code == 200) {
      iconss.calendarData = res.data;
      //getCalDetailedListFN(date);
    }
  });
};
//日历详情
const getCalDetailedListFN = (date: any) => {
  getCalDetailedList({
    pageNum: calendarPage.value,
    pageSize: calendarPageSize.value,
    biddingDate: date,
  }).then((res: any) => {
    if (res.code == 200) {
      if (calendarPage.value === 1) {
        iconss.rili = res.data.records;
      } else {
        iconss.rili = res.data.records;
        //iconss.rili = iconss.rili.concat(res.data.records);
      }
      iconss.rili_total = res.data.total;
    }
  });
};
//新闻数据
let iconNews:any = reactive({
  iconN: [],
  iconNo: [],
  iconIMG: [],
  iconNoId: [],
  total: 1,
});
//分页
const data = reactive({
  screenList2: [] as any[],
  endBidTime: "",
  bidEndPrice: undefined,
  beginBidTime: "",
  bidStartPrice: undefined,
  text: "",
  page: {
    current: 1,
    size: 4,
    total: 4,
    sizes: [4],
  },
  fromData: {
    timeStart: [
      {
        toISOString: () => {
          return "";
        },
      },
      {
        toISOString: () => {
          return "";
        },
      },
    ],
  },
});

//watch监听
watch(screenListIndex, (newValue, oldValue) => {
  iconss.current = 1;
  iconss.icon = [];
  iconss.bidType = data.screenList2[0].dictData[newValue[0]].dictValue;
  iconss.region = data.screenList2[1].dictData[newValue[1]].dictValue;
  iconss.bidPriceQuery = data.screenList2[2].dictData[newValue[2]].dictValue;
  iconss.rentTypeQuery = data.screenList2[3].dictData[newValue[3]].dictValue;
  iconss.themeQuery = data.screenList2[4].dictData[newValue[4]].dictValue;
  iconss.bidTimeQuery = data.screenList2[5].dictData[newValue[5]].dictValue;
  BiComFN(
    iconss.region,
    iconss.bidType,
    iconss.themeQuery,
    iconss.bidTimeQuery,
    iconss.bidPriceQuery,
    iconss.rentTypeQuery
  );
});
watch(calendarvalue, (newValue, oldValue) => {
  //console.log(calendarvalue)
  let check_new = dataFormat(newValue, "yyyy-mm");
  let check_old = dataFormat(oldValue, "yyyy-mm");
  if (
    check_new != check_old ||
    dataFormat(new Date(), "yyyy-mm-dd") == dataFormat(newValue, "yyyy-mm-dd")
  ) {
    // console.log(dataFormat(newValue, "yyyy-mm"));
    // console.log(dataFormat(oldValue, "yyyy-mm"));
    let time = dataFormat(newValue, "yyyy-mm-dd");
    let time2 = dataFormat(newValue, "yyyy-mm-dd hh:mm:ss");
    getCalListFN(time);
    //重置页码
    calendarPage.value = 1;
    getCalDetailedListFN(time2);
  }
});

const textContent = (date: any) => {
  return iconss.calendarData.filter((item: any) => {
    return date === item.current;
  });
};
//条件筛选列表
const queryDictFN = () => {
  queryDict().then((res) => {
    if (res.code == 200) {
      let arr: any = [];
      let id = 1;
      res.data.forEach((e: any) => {
        if (e.dictData.length) {
          e.dictData.forEach((v: any, i: number) => {
            id++;
            v.id = id;
            if (i == 0) {
              v.checked = true;
            } else {
              v.checked = false;
            }
          });
        }
        if (
          e.type == "bidTypeArray" ||
          e.type == "cityArray" ||
          e.type == "regionArray" ||
          e.type == "bidPriceQuery" ||
          e.type == "rentTypeQueryArray" ||
          e.type == "themeQueryArray" ||
          e.type == "bidTimeQuery"
        ) {
          arr.push(e);
        }
      });
      let newElement = {
        name: "标的区域",
        type: "regionArray",
        dictData: [
          {
            dictValue: "-1",
            dictName: "全部",
            checked: true
          },
        ]
      }
      arr.splice(2, 0, newElement)
      data.screenList2 = arr;
    }
  });
};
//加载更多
const Loadmore = () => {
  iconss.current++;
  getNonLoginPageListFN();
};
//收起
const suohui = () => {
  iconss.current = 1;
  getNonLoginPageListFN();
  document.getElementById("target").scrollIntoView();
};
// const currentChange = (e) => {};
// const sizeChange = (e) => {};

//时间倒计时
const onStart = (time: any, index: any) => {
  // alert(type)
  let intervalId = setInterval(() => {
    let date: any = new Date(time).getTime();
    // let stopAt: any =
    //   new Date(date)
    //     .toLocaleString("chinese", { hour12: false })
    //     .split("/")
    //     .join("-") || 0;
    // if (stopAt) {
    //   stopAt = stopAt.replace(/-/g, "/");
    // }
    let stopAt = new Date(date).getTime() / 1000;
    let now: any = new Date().getTime() / 1000;
    let leftTime: number = parseInt(stopAt) - parseInt(now);

    if (leftTime < 0) {
      clearInterval(intervalId);
      var seconds: any = 0;
      iconss.Icon[index].registrationEndTime = `0天0小时0分钟0秒`;
      return;
    }
    //alert(9999)
    if (leftTime < 86400) {
      var hours: any = parseInt((leftTime / 3600).toString(), 10);
      var days: any = 0;
    } else {
      var days: any = parseInt((leftTime / 86400).toString(), 10);
      var hours: any = parseInt(((leftTime / 3600) % 24).toString(), 10);
    }
    var minutes: any = parseInt(((leftTime / 60) % 60).toString(), 10);
    var seconds: any = parseInt((leftTime % 60).toString(), 10);
    //console.log(`${days}天${hours}小时${minutes}分钟${seconds}秒`)
    //days + hours + minutes + seconds;
    iconss.Icon[
      index
    ].registrationEndTime = `${days}天${hours}小时${minutes}分钟${seconds}秒`;
    //return `${days}天${hours}小时${minutes}分钟${seconds}秒`
  }, 1000);
};

//分页查询标的
const getNonLoginPageListFN = async () => {
  await getNonLoginPageList({
    pageSize: 8,
    region: iconss.region,
    bidType: iconss.bidType,
    queryProgressType: 14,
    pageNum: iconss.current,
    bidEndPrice: data.bidEndPrice,
    bidStartPrice: data.bidStartPrice,
    bidTypeArray: iconss.bidTypeArray,
    regionArray: iconss.regionArray,
    cityArray:iconss.cityArray,
    rentTypeQueryArray: iconss.rentTypeQueryArray,
    themeQueryArray: iconss.themeQueryArray,
    bidIdsOrName: route.query.bidIds ? route.query.bidIds : "",
    bidPriceQuery: iconss.bidPriceQuery + "",
    bidTimeQuery: iconss.bidTimeQuery + "",
    beginBidTime: data.beginBidTime ? dataFormatfn(data.beginBidTime) : "",
    endBidTime: data.endBidTime ? dataFormatfn(data.endBidTime) : "",
    bidCodeOrName: data.text,
  }).then((data) => {
    if (iconss.current == 1) {
      iconss.icon = data.data.records;
    } else {
      iconss.icon.push(...data.data.records);
    }
    // iconss.icon.push(data.data.records);
    // console.log(iconss.icon);
    if (data.data.pages > iconss.current) {
      iconss.zhankai = true;
    } else {
      iconss.zhankai = false;
    }
    iconss.total = data.data.total;

    // console.log("===========", iconss.icon, iconss.icon.length);
  });
};

const BiComFN = async (
  region: any,
  bidType: any,
  themeQuery: any,
  bidTimeQuery: any,
  rentTypeQuery: any,
  bidPriceQuery: any
) => {
  await BiCom({
    pageSize: 8,
    region: iconss.region,
    bidType: iconss.bidType,
    queryProgressType: 14,
    themeQuery: iconss.themeQuery,
    pageNum: iconss.current,
    bidTimeQuery: iconss.bidTimeQuery,
    bidPriceQuery: iconss.bidPriceQuery,
    rentTypeQuery: 1,
    bidEndPrice: data.bidEndPrice,
    bidStartPrice: data.bidStartPrice,
    bidTypeArray: iconss.bidTypeArray,
    regionArray: iconss.regionArray,
    rentTypeQueryArray: iconss.rentTypeQueryArray,
    themeQueryArray: iconss.themeQueryArray,
    bidIdsOrName: route.query.bidIds ? route.query.bidIds : "",
    beginBidTime: data.fromData.timeStart
      ? dataFormatfn(data.fromData.timeStart[0])
      : "",
    endBidTime: data.fromData.timeStart
      ? dataFormatfn(data.fromData.timeStart[1])
      : "",
  }).then((data) => {
    if (iconss.current == 1) {
      iconss.icon = data.data.records;
    } else {
      iconss.icon.push(...data.data.records);
    }
    // iconss.icon.push(data.data.records);
    console.log(iconss.icon);
    if (data.data.pages > iconss.current) {
      iconss.zhankai = true;
    } else {
      iconss.zhankai = false;
    }
    iconss.total = data.data.total;
  });
};




//交易日历
const a = new Date();
console.log(a);
var day = a.getDay(); // 获取星期几
var date = a.getDate(); //获取当前日
var month = a.getMonth(); //获取月 未加一
var year = a.getFullYear(); //获取年
var arr = [];
// var object = {
//   date: "",
//   week: ""
// }
for (let i = 0; i < 7; i++) {
  var d = new Date(year, month, date);
  var m: any = d.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var r: any = d.getDate();
  r = r < 10 ? +r : r;
  var monthdate = r;
  var week: any = d.getDay();

  if (week == day) {
    week = "今天";
  } else if (week == day + 1 || week == day - 6) {
    week = "明天";
  }
  switch (week) {
    case 0:
      week = "周日";
      break;
    case 1:
      week = "周一";
      break;
    case 2:
      week = "周二";
      break;
    case 3:
      week = "周三";
      break;
    case 4:
      week = "周四";
      break;
    case 5:
      week = "周五";
      break;
    case 6:
      week = "周六";
      break;
  }
  arr.push({ monthdate, week });
  date++;
}

const dataFormatfn = (time: any) => {
  if (time == null || time == "" || !time.getDate) {
    return "";
  }
  return dataFormat(time, "yyyy-mm-dd HH:mm:ss");
};

const changeQuery = (e: any) => {
  console.log('接收到参数',e);
  iconss.current = 1;
  iconss.themeQueryArray = e.themeQueryArray;
  iconss.rentTypeQueryArray = e.rentTypeQueryArray;
  iconss.bidTypeArray = e.bidTypeArray;
  iconss.regionArray = e.regionArray;
  iconss.bidTimeQuery = e.bidTimeQuery;
  iconss.bidPriceQuery = e.bidPriceQuery;
  iconss.cityArray = e.cityArray;
  data.bidEndPrice = e.bidEndPrice;
  data.bidStartPrice = e.bidStartPrice;
  data.beginBidTime = e.beginBidTime;
  data.endBidTime = e.endBidTime;
  data.text = e.bidCodeOrName;
  getNonLoginPageListFN();
};

//首页时事新闻跳转详情
const routePush = (item: any) => {
  let url = router.resolve({
    path: "/Newsdetail",
    query: { id: item },
  });
  window.open(url.href);
};
//首页时事新闻加载更多
const routePus = (item: any) => {
  let url = router.resolve({
    path: "/NoticeDetails",
    query: {
      id: item,
    },
  });
  window.open(url.href);
};
//首页公告跳转详情
const routclickID = (item: any) => {
  let url = router.resolve({
    path: "/Transactiondetails",
    query: {
      bidIds: item,
    },
  });
  window.open(url.href);
};
//公告变更
let gongclick = function (num: any) {
  notice_ids.notice_id = num;
};
//首页商机无限跳转详情
const routclickSJ = (item: any) => {
  let url = router.resolve({
    path: "/PlatformNoti",
    query: {
      id: item,
    },
  });
  window.open(url.href);
};
//首页招租商机跳转
const clice = (item: any) => {
  let url = router.resolve({
    path: "/Mydetails",
    query: {
      bidIds: item,
    },
  });
  window.open(url.href);
};
//跳转标的列表
const routCompetitionView = (res: number | string) => {
  let url = router.resolve({
    path: "/CompetitionView",
    query: {
      queryProgressType: res,
    },
  });
  window.open(url.href);
};
//跳转今日竞价
const routCompetitionView2 = (res: number | string) => {
  let url = router.resolve({
    path: "/biddingHall",
  });
  window.open(url.href);
};
//带上搜索的的时候的标的列表
const BiComFNS = () => {
  BiComFN(
    iconss.region,
    iconss.bidType,
    iconss.themeQuery,
    iconss.bidTimeQuery,
    iconss.bidPriceQuery,
    iconss.rentTypeQuery
  );
};
const bicomfn = async () => {
  const { data } = await BiCom({
    pageNum: 1,
    pageSize: 1000,
    queryProgressType: 20,
  });
  iconss.Icon = data.records;
  for (let i = 0; i < iconss.Icon.length; i++) {
    onStart(iconss.Icon[i].registrationEndTime, i);
  }
  iconss.total = data.total;


  await nextTick(() => {
    console.log(listWrap.value)
    if(!listWrap.value && scrolltimer) {
      clearInterval(scrolltimer)
    }
    scrolltimer = setInterval(() => {
      // 元素自增距离顶部1像素

      listWrap.value!.scrollTop += 1;
      // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      if (
          listWrap.value!.clientHeight + listWrap.value!.scrollTop >=
          listWrap.value!.scrollHeight - 1
      ) {
        // 回到第一条数据
        listWrap.value!.scrollTop = 0;
      }
    }, 150); // 滚动速度
  });
};
const routclick = (res: number | string) => {
  let url = router.resolve(`/${res}`);
  window.open(url.href);
};
//标的信息输入框
let Bjinput = ref([]);
__expose({
  ...toRefs(data),
  bicomfn,
  Loadmore,
  BiComFNS,
  routePush,
  routclick,
  getCalListFN,
  getCalDetailedListFN,
  textContent,
  routCompetitionView,
  routCompetitionView2,
});

return (_ctx: any,_cache: any) => {
  const _component_ArrowRight = _resolveComponent("ArrowRight")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_calendar = _resolveComponent("el-calendar")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Banner), {
        bannerList: _unref(iconss).bannerList
      }, null, 8, ["bannerList"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_row, { gutter: 24 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 16 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "tabItem active" }, "时事新闻", -1)),
                    _createElementVNode("div", {
                      class: "content-titles-more",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (routclick('mycurrentaffairs')))
                    }, [
                      _cache[13] || (_cache[13] = _createTextVNode(" 更多 ")),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowRight)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  (_unref(iconss).loading)
                    ? (_openBlock(), _createBlock(_component_el_skeleton, {
                        key: 0,
                        loading: _unref(iconss).loading,
                        animated: ""
                      }, {
                        template: _withCtx(() => [
                          _createVNode(_component_el_skeleton, {
                            class: "mt20",
                            rows: 6,
                            animated: ""
                          })
                        ]),
                        _: 1
                      }, 8, ["loading"]))
                    : (_unref(iconNews).iconIMG.length)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "flex-row mt24 flex-between shouzhi",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (routePush(_unref(iconNews).iconNoId)))
                        }, [
                          _createVNode(_component_el_carousel, {
                            height: "210px",
                            interval: 5000,
                            arrow: "never",
                            style: {"width":"48%"}
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(iconNews).iconIMG, (item) => {
                                return (_openBlock(), _createBlock(_component_el_carousel_item, {
                                  key: item,
                                  class: "bg-c",
                                  style: _normalizeStyle({ backgroundImage: 'url(' + item + ')' })
                                }, null, 8, ["style"]))
                              }), 128))
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(iconNews).iconN), 1),
                            _createElementVNode("div", {
                              class: "line-hidden lh26 fs16",
                              innerHTML: _unref(iconNews).iconNo
                            }, null, 8, _hoisted_9),
                            _createElementVNode("div", {
                              class: "abs",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (routePush(_unref(iconNews).iconNoId))),
                              style: {"bottom":"0","color":"#3d4a7f"}
                            }, " 查看详情> ")
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_el_empty, {
                            style: {"padding":"8px 0px"},
                            description: "暂无资源"
                          })
                        ]))
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 8,
            style: {"padding-left":"0"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "tabItem active" }, "平台通知", -1)),
                    _createElementVNode("div", {
                      class: "content-titles-more",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (routclick('Holidaynotice')))
                    }, [
                      _cache[15] || (_cache[15] = _createTextVNode(" 更多 ")),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowRight)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  (_unref(iconss).loading)
                    ? (_openBlock(), _createBlock(_component_el_skeleton, {
                        key: 0,
                        loading: _unref(iconss).loading,
                        animated: ""
                      }, {
                        template: _withCtx(() => [
                          _createVNode(_component_el_skeleton, {
                            class: "mt20",
                            rows: 6,
                            animated: ""
                          })
                        ]),
                        _: 1
                      }, 8, ["loading"]))
                    : (_unref(iconss).Notice.length)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(iconss).Notice.slice(0, 6), (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "flex-between align-center",
                            style: {"margin":"20px 0 0 0"},
                            key: index
                          }, [
                            _cache[18] || (_cache[18] = _createElementVNode("div", {
                              class: "dot",
                              style: {"background-color":"#d0d0d0","border-radius":"50%","width":"5px","height":"5px"}
                            }, null, -1)),
                            _createElementVNode("div", _hoisted_14, [
                              _cache[17] || (_cache[17] = _createElementVNode("div", {
                                class: "mr10 lw-5 lh-5",
                                style: {"background-color":"#d0d0d0","border-radius":"100%"}
                              }, null, -1)),
                              (item.topFlag == 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, " 置顶 "))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", {
                                class: "shouzhi el-1",
                                onClick: ($event: any) => (routePus(item.noticeId))
                              }, _toDisplayString(item.noticeTitle), 9, _hoisted_16)
                            ]),
                            _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(parseTime)(item.createTime, '{y}-{m}-{d}')), 1)
                          ]))
                        }), 128))
                      : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createVNode(_component_el_empty, {
                            style: {"padding":"8px 0px"},
                            description: "暂无资源"
                          })
                        ]))
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createVNode(_component_el_row, { gutter: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", {
                    class: "shouzhi",
                    style: {"display":"flex","align-items":"center"},
                    onClick: routCompetitionView2
                  }, [
                    _cache[20] || (_cache[20] = _createElementVNode("img", {
                      style: {"width":"68px","height":"68px"},
                      src: _imports_0,
                      alt: ""
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_22, [
                      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "home-image-text fs18" }, "今日竞价", -1)),
                      _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(iconss).data.biddingCount), 1)
                    ])
                  ]),
                  _createElementVNode("div", {
                    class: "shouzhi",
                    style: {"display":"flex","align-items":"center"},
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (routCompetitionView(16)))
                  }, [
                    _cache[22] || (_cache[22] = _createElementVNode("img", {
                      style: {"width":"68px","height":"68px"},
                      src: _imports_1,
                      alt: ""
                    }, null, -1)),
                    _createElementVNode("div", null, [
                      _cache[21] || (_cache[21] = _createElementVNode("div", { class: "home-image-text fs18" }, "今日发布", -1)),
                      _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(iconss).data.publishCount), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _cache[25] || (_cache[25] = _createElementVNode("img", {
                      style: {"width":"68px","height":"68px"},
                      src: _imports_2,
                      alt: ""
                    }, null, -1)),
                    _createElementVNode("div", null, [
                      _cache[24] || (_cache[24] = _createElementVNode("div", { class: "home-image-text fs18" }, "累计成交额", -1)),
                      _createElementVNode("div", _hoisted_26, [
                        _createTextVNode(_toDisplayString(_unref(iconss).data.totalDealMoney) + " ", 1),
                        _cache[23] || (_cache[23] = _createElementVNode("div", { style: {"font-size":"12px","margin":"10px 0 0 10px"} }, " 万元 ", -1))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _cache[27] || (_cache[27] = _createElementVNode("img", {
                      style: {"width":"68px","height":"68px"},
                      src: _imports_3,
                      alt: ""
                    }, null, -1)),
                    _createElementVNode("div", null, [
                      _cache[26] || (_cache[26] = _createElementVNode("div", { class: "home-image-text fs18" }, "累计成交单", -1)),
                      _createElementVNode("div", _hoisted_28, _toDisplayString(_unref(iconss).data.totalDealCount), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_29, [
      _createVNode(_component_el_row, { gutter: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _cache[28] || (_cache[28] = _createElementVNode("div", {
                    class: "flex-column fs32 center-block tx-big",
                    style: {"color":"#000000"}
                  }, [
                    _createTextVNode(" 招租选择 "),
                    _createElementVNode("div", { style: {"width":"64px","height":"3px","background":"#d2a04d","margin-top":"10px"} })
                  ], -1)),
                  _createVNode(_unref(FilterBar), {
                    dataList: data.screenList2,
                    onChange: changeQuery,
                    onClearAll: clearAll
                  }, null, 8, ["dataList"]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(iconss).icon, (option, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "rent-content ml15 mt20 uball shouzhi",
                          key: index,
                          style: {"width":"315px","height":"320px"},
                          onClick: ($event: any) => (clice(option.bidIds))
                        }, [
                          _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("img", {
                              style: {"width":"100%","height":"100%","object-fit":"cover"},
                              src: 
                          option.imgUrl
                            ? option.imgUrl
                            : require('../assets/image/icon_Cumulative2.png')
                        ,
                              alt: "预告标的"
                            }, null, 8, _hoisted_36)
                          ]),
                          _createElementVNode("div", {
                            class: "rent-content-title ml20 mt10 el-1",
                            onClick: ($event: any) => (clice(option.bidIds))
                          }, _toDisplayString(option.bidName), 9, _hoisted_37),
                          _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("div", _hoisted_39, _toDisplayString(option.startPrice ? option.startPrice : "-") + _toDisplayString(option.priceUnitName), 1),
                            _createElementVNode("div", _hoisted_40, _toDisplayString(option.autonomyFlag == 1 ? "查看" : "报名"), 1)
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_41, [
                              _createElementVNode("div", _hoisted_42, _toDisplayString(option.biddingType ? "正向报价" : "反向报价"), 1),
                              _createElementVNode("div", _hoisted_43, _toDisplayString(option.releaseTime), 1)
                            ])
                          ])
                        ], 8, _hoisted_34))
                      }), 128))
                    ])
                  ]),
                  (_unref(iconss).total <= 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                        _createVNode(_component_el_empty, { description: "暂无数据" })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_45, [
                    (_unref(iconss).zhankai)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                          _createElementVNode("span", {
                            class: "plr20 shouzhi",
                            onClick: Loadmore
                          }, "展开更多"),
                          (_unref(iconss).current > 1)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: "plr20 shouzhi",
                                onClick: suohui
                              }, "收起"))
                            : _createCommentVNode("", true)
                        ]))
                      : (!_unref(iconss).zhankai && _unref(iconss).current != 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                            _createElementVNode("span", {
                              class: "plr20 shouzhi",
                              onClick: suohui
                            }, "收起")
                          ]))
                        : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_48, [
      _createVNode(_component_el_row, { gutter: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: 12,
            style: {"padding-right":"6px"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("div", _hoisted_50, [
                  _createElementVNode("div", _hoisted_51, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["tabItem fs18", { active: _unref(notice_ids).notice_id == 1 }]),
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(gongclick)(1)))
                    }, " 成交公告 ", 2),
                    _createElementVNode("button", {
                      class: _normalizeClass(["tabItem fs18", { active: _unref(notice_ids).notice_id == 2 }]),
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(gongclick)(2)))
                    }, " 变更公告 ", 2),
                    _createElementVNode("button", {
                      class: _normalizeClass(["tabItem fs18", { active: _unref(notice_ids).notice_id == 3 }]),
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(gongclick)(3)))
                    }, " 异常公告 ", 2),
                    _createElementVNode("div", {
                      class: "content-titles-more",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (routclick('signup')))
                    }, [
                      _cache[29] || (_cache[29] = _createTextVNode(" 更多 ")),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowRight)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _withDirectives(_createElementVNode("div", null, [
                    (_unref(iconss).NoTi.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(iconss).NoTi.slice(0, 6), (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "flex-between align-center shouzhi",
                              style: {"margin":"20px 0 0 0"},
                              key: index
                            }, [
                              _createElementVNode("div", _hoisted_53, [
                                _cache[30] || (_cache[30] = _createElementVNode("div", { style: {"background-color":"#d0d0d0","border-radius":"100%","width":"5px","height":"5px","margin-right":"10px"} }, null, -1)),
                                (item.topFlag == 1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_54, " 置顶 "))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", {
                                  class: "shouzhi el-1 lw-400",
                                  onClick: ($event: any) => (routclickID(item.noticeId))
                                }, _toDisplayString(item.noticeTitle), 9, _hoisted_55)
                              ]),
                              _createElementVNode("div", null, _toDisplayString(_unref(parseTime)(item.createTime, '{y}-{m}-{d}')), 1)
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_56, [
                          _createVNode(_component_el_empty, {
                            style: {"padding":"8px 0px"},
                            description: "暂无资源"
                          })
                        ]))
                  ], 512), [
                    [_vShow, _unref(notice_ids).notice_id == 1]
                  ]),
                  _withDirectives(_createElementVNode("div", null, [
                    (_unref(iconss).RenNo.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(iconss).RenNo.slice(0, 6), (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "shouzhi flex-between align-center",
                              style: {"margin":"20px 0 0 0"},
                              key: index
                            }, [
                              _createElementVNode("div", _hoisted_58, [
                                _cache[31] || (_cache[31] = _createElementVNode("div", { style: {"background-color":"#d0d0d0","border-radius":"100%","width":"5px","height":"5px","margin-right":"10px"} }, null, -1)),
                                (item.topFlag == 1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_59, " 置顶 "))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", {
                                  class: "shouzhi el-1 lw-400",
                                  onClick: ($event: any) => (routclickID(item.noticeId))
                                }, _toDisplayString(item.noticeTitle), 9, _hoisted_60)
                              ]),
                              _createElementVNode("div", null, _toDisplayString(_unref(parseTime)(item.createTime, '{y}-{m}-{d}')), 1)
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_61, [
                          _createVNode(_component_el_empty, {
                            style: {"padding":"8px 0px"},
                            description: "暂无资源"
                          })
                        ]))
                  ], 512), [
                    [_vShow, _unref(notice_ids).notice_id == 2]
                  ]),
                  _withDirectives(_createElementVNode("div", null, [
                    (_unref(iconss).ErNo.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(iconss).ErNo.slice(0, 6), (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "flex-between align-center shouzhi",
                              style: {"margin":"20px 0 0 0"},
                              key: index
                            }, [
                              _createElementVNode("div", _hoisted_63, [
                                _cache[32] || (_cache[32] = _createElementVNode("div", { style: {"background-color":"#d0d0d0","border-radius":"100%","width":"5px","height":"5px","margin-right":"10px"} }, null, -1)),
                                (item.topFlag == 1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_64, " 置顶 "))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", {
                                  class: "shouzhi el-1 lw-400",
                                  onClick: ($event: any) => (routclickID(item.noticeId))
                                }, _toDisplayString(item.noticeTitle), 9, _hoisted_65)
                              ]),
                              _createElementVNode("div", null, _toDisplayString(_unref(parseTime)(item.createTime, '{y}-{m}-{d}')), 1)
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_66, [
                          _createVNode(_component_el_empty, {
                            style: {"padding":"8px 0px"},
                            description: "暂无资源"
                          })
                        ]))
                  ], 512), [
                    [_vShow, _unref(notice_ids).notice_id == 3]
                  ])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 12,
            style: {"padding-left":"6px"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_67, [
                _createElementVNode("div", _hoisted_68, [
                  _createElementVNode("div", _hoisted_69, [
                    _cache[34] || (_cache[34] = _createElementVNode("div", { class: "tabItem active" }, "商机无限", -1)),
                    _createElementVNode("div", {
                      class: "content-titles-more",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (routclick('businessopportunity')))
                    }, [
                      _cache[33] || (_cache[33] = _createTextVNode(" 更多 ")),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowRight)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  (_unref(iconss).sj.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(iconss).sj.slice(0, 6), (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "shouzhi flex-between align-center",
                            style: {"margin":"20px 0 0 0"},
                            key: index
                          }, [
                            _createElementVNode("div", _hoisted_71, [
                              _cache[35] || (_cache[35] = _createElementVNode("div", { style: {"background-color":"#d0d0d0","border-radius":"100%","width":"5px","height":"5px","margin-right":"10px"} }, null, -1)),
                              (item.topFlag == 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_72, " 置顶 "))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", {
                                class: "shouzhi el-1 lw-400",
                                style: {"line-height":"19px"},
                                onClick: ($event: any) => (routclickSJ(item.newsIds))
                              }, _toDisplayString(item.title), 9, _hoisted_73)
                            ]),
                            _createElementVNode("div", null, _toDisplayString(_unref(parseTime)(item.createTime, '{y}-{m}-{d}')), 1)
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_74, [
                        _createVNode(_component_el_empty, {
                          style: {"padding":"8px 0px"},
                          description: "暂无资源"
                        })
                      ]))
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_75, [
      _createVNode(_component_el_row, { gutter: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_76, [
                _createElementVNode("div", _hoisted_77, [
                  _cache[37] || (_cache[37] = _createElementVNode("div", {
                    class: "flex-column fs32 center-block tx-big",
                    style: {"color":"#000000"}
                  }, [
                    _createTextVNode(" 交易日历 "),
                    _createElementVNode("div", { style: {"width":"64px","height":"3px","background":"#d2a04d","margin-top":"10px"} })
                  ], -1)),
                  _createElementVNode("div", _hoisted_78, [
                    _createVNode(_component_el_row, { gutter: 20 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 24 }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_79, [
                              _createVNode(_component_el_config_provider, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_80, [
                                    _createVNode(_component_el_calendar, {
                                      modelValue: _unref(calendarvalue),
                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_isRef(calendarvalue) ? (calendarvalue).value = $event : calendarvalue = $event))
                                    }, {
                                      "date-cell": _withCtx(({ data }) => [
                                        _createElementVNode("div", {
                                          onClick: ($event: any) => (dayClick(data))
                                        }, [
                                          _createElementVNode("p", {
                                            class: _normalizeClass(["data_a tx-c fs24", data.isSelected ? 'is-selected' : '']),
                                            style: {"transform":"translateY(5px)"}
                                          }, _toDisplayString(data.day.split("-").slice(1)[1]), 3),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(textContent(data.day), (item, index) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              class: "tx-r c-c08 pb10",
                                              key: index
                                            }, _toDisplayString(item.count) + "单 ", 1))
                                          }), 128))
                                        ], 8, _hoisted_81)
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", _hoisted_82, [
                              (_unref(iconss).rili.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                                    _createElementVNode("div", _hoisted_84, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(iconss).rili, (option, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          onClick: ($event: any) => (clice(option.bidIds)),
                                          class: "mt20 uball lw-640 lh-180 lwbox shouzhi",
                                          key: index
                                        }, [
                                          _createElementVNode("div", _hoisted_86, [
                                            _createElementVNode("img", {
                                              style: {"width":"100%","height":"100%","object-fit":"cover"},
                                              src: option.imgUrl,
                                              alt: ""
                                            }, null, 8, _hoisted_87)
                                          ]),
                                          _createElementVNode("div", _hoisted_88, [
                                            _createElementVNode("div", _hoisted_89, _toDisplayString(option.bidName), 1),
                                            _createElementVNode("div", _hoisted_90, " 竞价日期： " + _toDisplayString(option.biddingDate), 1),
                                            _createElementVNode("div", _hoisted_91, _toDisplayString(option.startPrice ? option.startPrice : "-") + _toDisplayString(option.priceUnitName), 1),
                                            _createElementVNode("div", _hoisted_92, " 浏览次数：" + _toDisplayString(option.views), 1),
                                            _createElementVNode("div", _hoisted_93, [
                                              _createElementVNode("button", _hoisted_94, _toDisplayString(option.presentStatusName), 1)
                                            ])
                                          ])
                                        ], 8, _hoisted_85))
                                      }), 128))
                                    ]),
                                    _createElementVNode("div", _hoisted_95, [
                                      _createElementVNode("div", _hoisted_96, [
                                        _createVNode(_component_el_pagination, {
                                          small: "",
                                          background: "",
                                          layout: "total, sizes, prev, pager, next, jumper, ->",
                                          class: "mt-4 mt10",
                                          "page-size": pageSize.value,
                                          "onUpdate:pageSize": _cache[11] || (_cache[11] = ($event: any) => ((pageSize).value = $event)),
                                          "page-sizes": [4],
                                          total: _unref(iconss).rili_total,
                                          onCurrentChange: currentPage,
                                          "current-page": calendarPage.value
                                        }, null, 8, ["page-size", "total", "current-page"])
                                      ])
                                    ])
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_97, _cache[36] || (_cache[36] = [
                                    _createElementVNode("div", {
                                      class: "tx-c",
                                      style: {"color":"#3d4a7f"}
                                    }, " 无更多数据了 ", -1)
                                  ])))
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_98, [
      _createElementVNode("div", _hoisted_99, [
        _cache[39] || (_cache[39] = _createElementVNode("div", {
          class: "fs32 center-block flex-column tx-big",
          style: {"color":"#fff"}
        }, [
          _createTextVNode(" 竞价时事 "),
          _createElementVNode("div", {
            class: "mt10 bc-fff",
            style: {"width":"64px","height":"3px"}
          })
        ], -1)),
        _createVNode(_component_el_row, { gutter: 20 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_100, [
                  _createElementVNode("div", _hoisted_101, [
                    _createElementVNode("div", _hoisted_102, [
                      _cache[38] || (_cache[38] = _createElementVNode("div", { class: "bidding-header" }, [
                        _createElementVNode("div", { class: "cell-title" }, "标的编号"),
                        _createElementVNode("div", { class: "cell-title" }, "标的名称"),
                        _createElementVNode("div", { class: "cell-title" }, "报价模式"),
                        _createElementVNode("div", { class: "cell-title" }, "挂牌价"),
                        _createElementVNode("div", { class: "cell-title" }, "价格单位"),
                        _createElementVNode("div", { class: "cell-title" }, "报名结束时间"),
                        _createElementVNode("div", { class: "cell-title" }, "竞价状态")
                      ], -1)),
                      _createElementVNode("div", {
                        class: "bidding-content-wrapper",
                        ref_key: "listWrap",
                        ref: listWrap
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(iconss).Icon, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "bidding-item",
                            key: index
                          }, [
                            _createElementVNode("div", _hoisted_103, _toDisplayString(item.bidIds), 1),
                            _createElementVNode("div", {
                              class: "cell-value",
                              onClick: ($event: any) => (clice(item.bidIds))
                            }, _toDisplayString(item.bidName), 9, _hoisted_104),
                            _createElementVNode("div", _hoisted_105, _toDisplayString(item.biddingType == 0 ? "正向报价" : "反向报价"), 1),
                            _createElementVNode("div", _hoisted_106, _toDisplayString(item.startPrice), 1),
                            _createElementVNode("div", _hoisted_107, _toDisplayString(item.priceUnitName), 1),
                            _createElementVNode("div", _hoisted_108, _toDisplayString(item.registrationEndTime), 1),
                            _createElementVNode("div", _hoisted_109, [
                              _createVNode(_component_el_button, {
                                class: "addressCount ub0 mouse",
                                size: "small"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.biddingStatusName
                            ? item.biddingStatusName
                            : item.progressName), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]))
                        }), 128))
                      ], 512)
                    ]),
                    _createElementVNode("div", _hoisted_110, [
                      _createElementVNode("button", {
                        class: "bc-fff lw-128 lh-40 uc-4",
                        style: {"border":"1px solid #d0d1d4"},
                        onClick: _cache[12] || (_cache[12] = ($event: any) => (routclick('biddingHall')))
                      }, " 前往竞价 ")
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_111, [
      _createVNode(_component_el_row, { gutter: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => _cache[40] || (_cache[40] = [
              _createElementVNode("div", { style: "" }, [
                _createElementVNode("div", { class: "" }, [
                  _createElementVNode("div", {
                    class: "fs32 center-block flex-column tx-big",
                    style: {"color":"#000000"}
                  }, [
                    _createTextVNode(" 合作伙伴 "),
                    _createElementVNode("div", { style: {"width":"64px","height":"3px","background":"#d2a04d","margin-top":"10px"} })
                  ]),
                  _createElementVNode("div", { class: "img_container" }, [
                    _createElementVNode("div", {
                      class: "center-block tx-c lw-320 lh-118 bc-fff",
                      style: {"box-shadow":"rgb(0 0 0 / 8%) 2px 3px 6px 1px"}
                    }, [
                      _createElementVNode("img", {
                        src: _imports_4,
                        alt: ""
                      })
                    ]),
                    _createElementVNode("div", {
                      class: "center-block tx-c lw-320 lh-118 bc-fff",
                      style: {"box-shadow":"rgb(0 0 0 / 8%) 2px 3px 6px 1px"}
                    }, [
                      _createElementVNode("img", {
                        src: _imports_5,
                        alt: ""
                      })
                    ]),
                    _createElementVNode("div", {
                      class: "center-block tx-c tx-c lw-320 lh-118 bc-fff",
                      style: {"box-shadow":"rgb(0 0 0 / 8%) 2px 3px 6px 1px"}
                    }, [
                      _createElementVNode("img", {
                        src: _imports_4,
                        alt: ""
                      })
                    ]),
                    _createElementVNode("div", {
                      class: "center-block tx-c tx-c lw-320 lh-118 bc-fff",
                      style: {"box-shadow":"rgb(0 0 0 / 8%) 2px 3px 6px 1px"}
                    }, [
                      _createElementVNode("img", {
                        src: _imports_5,
                        alt: ""
                      })
                    ])
                  ])
                ])
              ], -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_unref(FixedMenu)),
    _createVNode(_unref(botUI))
  ]))
}
}

})