import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../assets/image/xcx.svg'
import _imports_1 from '../assets/image/companyWechat.png'


const _hoisted_1 = { class: "fixedMenu" }
const _hoisted_2 = ["onMouseenter", "onMouseleave", "onClick"]
const _hoisted_3 = { class: "menu-icon" }
const _hoisted_4 = {
  key: 0,
  src: _imports_0
}
const _hoisted_5 = {
  key: 1,
  src: _imports_1
}
const _hoisted_6 = { class: "rightName" }

import { reactive, watch, onMounted, onUnmounted, ref, inject } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'fixedMenu',
  setup(__props) {

const emitter: any = inject("emitter"); // Inject `emitter`
const sendChat = () => {
  emitter.emit("chat", true);
};
const showEnter = ref(0)
let datas = reactive({
  menuList: [
    {
      name: "回到顶部",
      icon: "Top",
      type: "toTop",
      isClick: true,
      size: 40,
    },
    {
      name: "联系电话",
      icon: "PhoneFilled",
      type: "phone",
      isClick: true,
      size: 40,
    },
    {
      name: "微信小程序",
      icon: "TakeawayBox",
      type: "facsimile",
      isClick: true,
      size: 40,
    },
    // {
    //   name: "邮箱",
    //   icon: "Paperclip",
    //   type: "email",
    //   isClick: false,
    //   size: 40,
    // },
    {
      name: "助理客服",
      icon: "ChatDotRound",
      type: "customer",
      isClick: true,
      size: 35,
    },
    {
      name: "企业微信",
      icon: "companyWechat",
      type: "companyWechat",
      isClick: true,
      size: 40,
    },
  ],
});

// 菜单操作
const menuOperate = (val: any) => {
  if (val.isClick) {
    switch (val.type) {
      case "toTop":
        goTop();
        break;
      case "customer":
        sendChat();
        break;
      case "facsimile":
        let url=require("@/assets/image/erweima.png");
        ElMessageBox.alert(
          `<img src="${url}" class="mt20 lw-150 lh-150" />`,
          "小程序二维码",
          {
            dangerouslyUseHTMLString: true,
            center: true,
          }
        );
        break;
      case "phone":
        //ElMessage.info("联系电话： 0591-83726089");
        ElMessageBox.alert(
          ` <div class="ptb10">四海易链运营中心： 0591-83726089</div>`,
          "联系电话",
          {
            dangerouslyUseHTMLString: true,
            center: true,
          }
        );
        break;
      case "companyWechat":
        const imageUrl = require("@/assets/image/qrcode-company.jpg");
        ElMessageBox.alert(
            `<img src="${imageUrl}" class="mt20 lw-150 lh-150" />`,
            "企业微信二维码",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              customClass: 'messageBoxTitle'
            }
        );
        break;
      default:
    }
  } else {
    ElMessage.warning("功能开发中,敬请期待！");
  }
};

//回到顶部的按钮是否显示
const showToScroll = ref(false);

//处理滚动，决定回到顶部的按钮是否显示出来
let handleScroll = () => {
  if (document.documentElement.scrollTop > 300) {
    showToScroll.value = true;
  } else {
    showToScroll.value = false;
  }
};
const itemMouseenter = (item: any)=> {
	if (item.name == '微信小程序') {
		showEnter.value = 1
	}
}
const itemMouseleave = (item: any)=> {
	if (item.name == '微信小程序') {
		showEnter.value = 0
	}
}
//回到顶部
const goTop = () => {
  document.querySelector("#app").scrollIntoView({
    behavior: "smooth", // 平滑过渡
    block: "start",
  });
};

onMounted(() => {
  //添加滚动事件
  window.addEventListener("scroll", handleScroll, false);
});
onUnmounted(() => {
  //移除滚动事件
  window.removeEventListener("scroll", handleScroll);
});

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(datas).menuList, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "menuItem",
        onMouseenter: ($event: any) => (itemMouseenter(item)),
        onMouseleave: ($event: any) => (itemMouseleave(item)),
        onClick: ($event: any) => (menuOperate(item))
      }, [
        _createElementVNode("div", _hoisted_3, [
          (item.type == 'facsimile')
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : (item.type == 'companyWechat')
              ? (_openBlock(), _createElementBlock("img", _hoisted_5))
              : (_openBlock(), _createBlock(_component_el_icon, {
                  key: 2,
                  class: "menu-el_icon",
                  size: item.size
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                  ]),
                  _: 2
                }, 1032, ["size"]))
        ]),
        _createElementVNode("span", _hoisted_6, _toDisplayString(item.name), 1)
      ], 40, _hoisted_2))
    }), 128))
  ], 512)), [
    [_vShow, showToScroll.value]
  ])
}
}

})