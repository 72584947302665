import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filterBar" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "numberRange"
}
const _hoisted_6 = { class: "box" }
const _hoisted_7 = { class: "input-box" }
const _hoisted_8 = { class: "input" }
const _hoisted_9 = {
  class: "input",
  style: {"margin-right":"15px"}
}
const _hoisted_10 = { class: "button-box" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 1,
  class: "dateRange"
}
const _hoisted_13 = { class: "info" }
const _hoisted_14 = {
  class: "input",
  style: {"margin-right":"15px"}
}
const _hoisted_15 = { class: "info" }
const _hoisted_16 = {
  class: "input",
  style: {"margin-right":"15px"}
}
const _hoisted_17 = { class: "item" }
const _hoisted_18 = { class: "info" }

import { reactive, watch, computed } from "vue";
import { getSubordinateAreaById } from "@/api/myhire";

export default /*@__PURE__*/_defineComponent({
  __name: 'FilterBar',
  props: {
    dataList: {}
  },
  emits: ["change", "clearAll"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
console.log("父传子", props);
const emit = __emit;
const options: any = reactive({
  min: null,
  max: null,
  date: [],
  filterData: props.dataList,
  queryParams: {},
});
watch(
  () => props.dataList,
  () => {
    options.filterData = props.dataList;
  }
);
const checkList = computed(() => {
  let aList: any = [];
  options.filterData.forEach((e: any, i: any) => {
    if (e.dictData.length) {
      e.dictData.forEach((v: any, m: any) => {
        if (v.checked && v.dictValue != "-1") {
          aList.push({
            ...v,
            index: i,
            subIndex: m,
            type: e.type,
          });
        }
      });
    }
  });
  return aList;
});
const createQueryParams = () => {
  let arr: any = options.filterData;
  let obj: any = {};
  arr.forEach((e: any) => {
    obj[e.type] = [];
    if (e.dictData.length) {
      e.dictData.forEach((v: any) => {
        if (v.checked && v.dictValue != -1) {
          obj[e.type].push(v.dictValue);
        }
      });
    }
  });
  obj.bidStartPrice = options.min;
  obj.bidEndPrice = options.max;
  obj.bidCodeOrName = options.text;
  if (options.date instanceof Array) {
    if (options.date.length >= 1) {
      obj.beginBidTime = options.date[0];
      obj.endBidTime = options.date[1];
    }
  }
  return obj;
};
const clear = () => {
  options.date = [];
  options.min = "";
  options.max = "";
  options.text = "";
  emit("clearAll");
  setTimeout(() => {
    emit("change", createQueryParams());
  }, 800);
};
const handleClose = (item: any) => {
  let index: any = item.index;
  let subIndex: any = item.subIndex;
  options.filterData[index]["dictData"][subIndex].checked = false;
  let nLen = options.filterData[index]["dictData"].length;
  options.filterData[index]["dictData"].forEach((e: any) => {
    if (!e.checked) {
      nLen--;
    }
  });
  if (!nLen) {
    options.filterData[index]["dictData"][0].checked = true;
  }
  emit("change", createQueryParams());
};

const subtext = (index: any) => {
  emit("change", createQueryParams());
};
const quantext = (index: any) => {
  emit("change", createQueryParams());
};

const subPrice = (index: any) => {
  options.filterData[index].dictData.forEach((e: any) => {
    e.checked = false;
  });
  emit("change", createQueryParams());
};
const empty = (index: any) => {
  options.filterData[index].dictData.forEach((e: any) => {
    options.min = "";
    options.max = "";
  });
  options.filterData[index].dictData.forEach((e: any) => {
    e.checked = false;
  });
  options.filterData[index].dictData[0].checked = true;
  emit("change", createQueryParams());
};

const subDate = (item: any, index: any) => {
  console.log(options.date)
  if (options.date == null || Object.keys(options.date).length == 0) {
    options.date = [];
    options.filterData[index].dictData.forEach((e: any) => {
      e.checked = false;
    });
    options.filterData[index].dictData[0].checked = true;
  }
  else {
    options.filterData[index].dictData.forEach((e: any) => {
      e.checked = false;
    });
  }
  emit("change", createQueryParams());
};

const checkItem = (item: any, subItem: any, subIndex: any) => {
  // console.log("========", item, subItem, subIndex);
  console.log(subItem.dictValue)

  if (item.type == 'cityArray') {
    if (subItem.dictValue == -1) {
      let dictData = [];
      dictData.unshift({
        dictValue: -1,
        dictName: '全部',
        checked: true
      })
      options.filterData[2].dictData = dictData;
    } else {
      getSubordinateAreaById({
        areaId: subItem.dictValue
      }).then(res => {
        if (res.code == 200) {
          let result = res.data;
          let dictData = [];
          console.log('result', result)
          for (let i = 0; i < result.length; i++) {
            dictData.push({
              dictValue: result[i].areaId,
              dictName: result[i].areaName
            })
          }
          dictData.unshift({
            dictValue: -1,
            dictName: '全部',
            checked: true
          })
          options.filterData[2].dictData = dictData;
        }
      })
    }
  }
  if (subIndex !== 0) {
    if (item.type === "bidTimeQuery" || item.type === "bidPriceQuery" || item.type == "cityArray") {
      options.min = null;
      options.max = null;
      item.dictData.forEach((v: any) => {
        v.checked = false;
      });
      item.dictData[subIndex].checked = true;
    } else {
      item.dictData[0].checked = false;
      subItem.checked = !subItem.checked;
      console.log('aaaa', item)
      const hasFalse = item.dictData.every((obj:any) => obj.checked == false);
      console.log(hasFalse)
      if(hasFalse){
        item.dictData[0].checked = true;
      }
    }
    if (item.type === "bidPriceQuery") {
      options.min = null;
      options.max = null;
    }
    if (item.type === "bidTimeQuery") {
      options.date = [];
    }
  } else {
    item.dictData.forEach((e: any) => {
      e.checked = false;
    });
    item.dictData[0].checked = true;
  }
  emit("change", createQueryParams());
};

return (_ctx: any,_cache: any) => {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.filterData, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "item",
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.name), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.dictData, (subItem, subIndex) => {
            return (_openBlock(), _createElementBlock("span", {
              class: _normalizeClass({ checked: subItem.checked }),
              key: subIndex,
              onClick: ($event: any) => (checkItem(item, subItem, subIndex))
            }, _toDisplayString(subItem.dictName), 11, _hoisted_4))
          }), 128)),
          (item.type === 'bidPriceQuery')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_el_input_number, {
                        style: {"width":"80px","margin":"0 0px","height":"32px"},
                        controls: false,
                        modelValue: options.min,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((options.min) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "symbol" }, "-", -1)),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_el_input_number, {
                        style: {"width":"80px","margin":"0 0px","height":"32px"},
                        controls: false,
                        modelValue: options.max,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((options.max) = $event))
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", {
                      onClick: ($event: any) => (empty(index)),
                      class: "clear"
                    }, "清空", 8, _hoisted_11),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: ($event: any) => (subPrice(index))
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("确认")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (item.type === 'bidTimeQuery')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_el_date_picker, {
                  modelValue: options.date,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((options.date) = $event)),
                  style: {"margin-right":"15px"},
                  type: "daterange",
                  "range-separator": "-",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  onFocus: ($event: any) => (subDate(item, index)),
                  onChange: ($event: any) => (subDate(item, index))
                }, null, 8, ["modelValue", "onFocus", "onChange"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.filterData, (item, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "item",
        key: index
      }, [
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "label" }, "公告信息", -1)),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_el_input, {
              style: {"width":"200px","margin":"0 0 0 10px","height":"32px"},
              controls: false,
              modelValue: options.text,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((options.text) = $event)),
              placeholder: "公告名称/编号"
            }, null, 8, ["modelValue"])
          ]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: ($event: any) => (subtext(index))
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("确认")
            ])),
            _: 2
          }, 1032, ["onClick"])
        ])
      ])), [
        [_vShow, item.type === 'noticeType']
      ])
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.filterData, (item, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "item",
        key: index
      }, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "label" }, "标的信息", -1)),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_el_input, {
              style: {"width":"200px","margin":"0 0 0 10px","height":"32px"},
              controls: false,
              modelValue: options.text,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((options.text) = $event)),
              placeholder: "标的名称/编号"
            }, null, 8, ["modelValue"])
          ]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: ($event: any) => (quantext(index))
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("确认")
            ])),
            _: 2
          }, 1032, ["onClick"])
        ])
      ])), [
        [_vShow, item.type === 'bidPriceQuery']
      ])
    }), 128)),
    _createElementVNode("div", _hoisted_17, [
      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "label" }, "已选条件", -1)),
      _createElementVNode("div", _hoisted_18, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkList.value, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_tag, {
            key: index,
            class: "mx-1",
            closable: "",
            "disable-transitions": false,
            onClose: ($event: any) => (handleClose(item, index))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.dictName), 1)
            ]),
            _: 2
          }, 1032, ["onClose"]))
        }), 128)),
        (checkList.value.length)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (clear(_ctx.index)))
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("一键清空")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})