<template>
  <!-- 返回顶部导航 -->
  <div class="fixedMenu" v-show="showToScroll">
    <div
      v-for="(item, index) in datas.menuList"
      :key="index"
      class="menuItem"
			@mouseenter="itemMouseenter(item)"
			@mouseleave="itemMouseleave(item)"
      @click="menuOperate(item)"
    >
      <div class="menu-icon">
        <img src="../assets/image/xcx.svg" v-if="item.type == 'facsimile'"/>
        <img src="../assets/image/companyWechat.png" v-else-if="item.type == 'companyWechat'"/>
        <el-icon class="menu-el_icon" :size="item.size" v-else>
          <component :is="item.icon" />
        </el-icon>
      </div>
      <span class="rightName">{{ item.name }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { reactive, watch, onMounted, onUnmounted, ref, inject } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";

const emitter: any = inject("emitter"); // Inject `emitter`
const sendChat = () => {
  emitter.emit("chat", true);
};
const showEnter = ref(0)
let datas = reactive({
  menuList: [
    {
      name: "回到顶部",
      icon: "Top",
      type: "toTop",
      isClick: true,
      size: 40,
    },
    {
      name: "联系电话",
      icon: "PhoneFilled",
      type: "phone",
      isClick: true,
      size: 40,
    },
    {
      name: "微信小程序",
      icon: "TakeawayBox",
      type: "facsimile",
      isClick: true,
      size: 40,
    },
    // {
    //   name: "邮箱",
    //   icon: "Paperclip",
    //   type: "email",
    //   isClick: false,
    //   size: 40,
    // },
    {
      name: "助理客服",
      icon: "ChatDotRound",
      type: "customer",
      isClick: true,
      size: 35,
    },
    {
      name: "企业微信",
      icon: "companyWechat",
      type: "companyWechat",
      isClick: true,
      size: 40,
    },
  ],
});

// 菜单操作
const menuOperate = (val: any) => {
  if (val.isClick) {
    switch (val.type) {
      case "toTop":
        goTop();
        break;
      case "customer":
        sendChat();
        break;
      case "facsimile":
        let url=require("@/assets/image/erweima.png");
        ElMessageBox.alert(
          `<img src="${url}" class="mt20 lw-150 lh-150" />`,
          "小程序二维码",
          {
            dangerouslyUseHTMLString: true,
            center: true,
          }
        );
        break;
      case "phone":
        //ElMessage.info("联系电话： 0591-83726089");
        ElMessageBox.alert(
          ` <div class="ptb10">四海易链运营中心： 0591-83726089</div>`,
          "联系电话",
          {
            dangerouslyUseHTMLString: true,
            center: true,
          }
        );
        break;
      case "companyWechat":
        const imageUrl = require("@/assets/image/qrcode-company.jpg");
        ElMessageBox.alert(
            `<img src="${imageUrl}" class="mt20 lw-150 lh-150" />`,
            "企业微信二维码",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              customClass: 'messageBoxTitle'
            }
        );
        break;
      default:
    }
  } else {
    ElMessage.warning("功能开发中,敬请期待！");
  }
};

//回到顶部的按钮是否显示
const showToScroll = ref(false);

//处理滚动，决定回到顶部的按钮是否显示出来
let handleScroll = () => {
  if (document.documentElement.scrollTop > 300) {
    showToScroll.value = true;
  } else {
    showToScroll.value = false;
  }
};
const itemMouseenter = (item: any)=> {
	if (item.name == '微信小程序') {
		showEnter.value = 1
	}
}
const itemMouseleave = (item: any)=> {
	if (item.name == '微信小程序') {
		showEnter.value = 0
	}
}
//回到顶部
const goTop = () => {
  document.querySelector("#app").scrollIntoView({
    behavior: "smooth", // 平滑过渡
    block: "start",
  });
};

onMounted(() => {
  //添加滚动事件
  window.addEventListener("scroll", handleScroll, false);
});
onUnmounted(() => {
  //移除滚动事件
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
.menu-el_icon.el-icon svg{
    width: 2em;
    height: 2em;
}


.fixedMenu {
  position: fixed;
  z-index: 9999999999 !important;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.28) 2px 2px 6px 1px;
  transition: all 0.3s;
  //width: 150px;
  width: 60px;
  .menuItem {
    width: 60px;
    //width: 150px;
    height: 80px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #666;
    overflow: hidden;
    .menu-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
    }
    .rightName {
      display: none;
      width: 90px;
      margin-left: 10px;
    }
		img {
			width: 2.6rem;
			//height: 2.6rem;
		}
    :deep(.el-icon) {
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 20px;
    }
  }
  .menuItem:last-child {
    border-bottom: none;
  }
}
.fixedMenu:hover {
  width: 170px;
  .menuItem {
    cursor: pointer;
    width: 170px;
    .rightName {
      display: block;
      font-size: 18px;
    }
  }
  .menuItem:hover {
    background: #d9af86;
    color: #fff;
  }
}

</style>
