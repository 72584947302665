import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/image/icon_logo1.png'
import _imports_1 from '@/assets/image/icon_logo2.png'
import _imports_2 from '@/assets/image/erweima.png'


const _hoisted_1 = {
  class: "footer",
  id: "footer"
}
const _hoisted_2 = { class: "footer-blogroll" }
const _hoisted_3 = {
  class: "footer-nav",
  style: {"display":"flex","justify-content":"space-between"}
}
const _hoisted_4 = { class: "friendShip" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 1 }


export default /*@__PURE__*/_defineComponent({
  __name: 'MyFooter',
  setup(__props) {

const footerList = [
  { name: "首页" },
  { name: "我要竞租" },
  { name: "公告公示" },
  { name: "竞价大厅" },
  { name: "咨询帮助" },
  { name: "关于我们" }
];
const footerList2 = [
  { name: "信息产业部ICP/IP地址信息备案序号：闽ICP备2020020263号-2",link:"https://beian.miit.gov.cn/#/Integrated/index" },
  { name: "公安备案：闽公网安备 35010302000752号",link:"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010302000752" },
  { name: "增值电信业务经营许可证：闽B2-20230923",link:require('@/assets/image/dianxin.png')},
  { name: "公司地址：福州市台江区鸡笼洲路2号福州城投榕发大厦4楼" },
  { name: "技术支持：四海易链技术团队" }
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"footer-blogroll-title\" data-v-f9c0001a>友情链接</div><div class=\"footer-blogroll-icon\" data-v-f9c0001a><div class=\"footer-blogroll-icon-item shouzhi\" data-v-f9c0001a><a href=\"https://paimai.caa123.org.cn/\" target=\"_blank\" data-v-f9c0001a><img src=\"" + _imports_0 + "\" alt data-v-f9c0001a><div class=\"footer-nav-item bianse\" data-v-f9c0001a>中拍平台</div></a></div><div class=\"footer-blogroll-icon-item shouzhi\" data-v-f9c0001a><a href=\"https://zc-paimai.taobao.com/\" target=\"_blank\" data-v-f9c0001a><img src=\"" + _imports_1 + "\" alt data-v-f9c0001a><div class=\"footer-nav-item bianse\" data-v-f9c0001a>阿里资产</div></a></div><div class=\"footer-blogroll-icon-item shouzhi\" data-v-f9c0001a><a href=\"https://paimai.caa123.org.cn/\" target=\"_blank\" data-v-f9c0001a><img src=\"" + _imports_0 + "\" alt data-v-f9c0001a><div class=\"footer-nav-item bianse\" data-v-f9c0001a>中拍平台</div></a></div><div class=\"footer-blogroll-icon-item shouzhi\" data-v-f9c0001a><a href=\"https://zc-paimai.taobao.com/\" target=\"_blank\" data-v-f9c0001a><img src=\"" + _imports_1 + "\" alt data-v-f9c0001a><div class=\"footer-nav-item bianse\" data-v-f9c0001a>阿里资产</div></a></div></div>", 2)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(footerList2, (item, index) => {
            return _createElementVNode("div", {
              key: index,
              style: {"padding":"6px 6px 6px 0"},
              class: "footer-nav-item mouse qu"
            }, [
              (item.link)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: item.link,
                    target: "_blank"
                  }, _toDisplayString(item.name), 9, _hoisted_5))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.name), 1))
            ])
          }), 64))
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", {
          class: "footer-nav",
          style: {"align-self":"flex-end","padding":"0"}
        }, [
          _createElementVNode("div", {
            class: "footer-nav-item",
            style: ""
          }, [
            _createElementVNode("img", {
              src: _imports_2,
              class: "mt20 lw-150 lh-150"
            }),
            _createElementVNode("div", { class: "tx-c mt5 fs14" }, [
              _createTextVNode(" 四海易链 "),
              _createElementVNode("br"),
              _createTextVNode("微信小程序 ")
            ])
          ])
        ], -1))
      ])
    ])
  ]))
}
}

})