import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bannerIndex banner" }
const _hoisted_2 = { class: "bannerItem shouzhi" }

import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { showAdvertising } from "@/api/home";

export default /*@__PURE__*/_defineComponent({
  __name: 'Banner',
  setup(__props) {

let route = useRoute();
let router = useRouter();
let datas: any = reactive({
  loading: false,
  banners: [
    {
      id: 0,
      url: require("../assets/image/img_bannerbg.png"),
      operates: true,
    },
  ],
});

// 路由跳转
const routclick = (res: number | string, id?: any) => {
  let url = router.resolve(`/${res}`);
  window.open(url.href);
};

const routDetail = (res: number | string, url?: any) => {
  if (url) {
    // let url = router.resolve({
    //   path: `/${res}`,
    //   query: {
    //     bidIds: id,
    //   },
    // });
    window.open(url);
  } else {
    let urls = router.resolve({
      path: `/`,
    });
    window.open(urls.href);
  }
};

onMounted(() => {
  showAdvertising({}).then((res) => {
    if (res.code == 200) {
      datas.banners = res.data;
    }
  });
});

return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_carousel, {
      interval: 3000,
      arrow: "always"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(datas).banners, (item) => {
          return (_openBlock(), _createBlock(_component_el_carousel_item, {
            key: item.id,
            onClick: ($event: any) => (routDetail('Mydetails', item.jumpUrl))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_el_image, {
                  src: item.url,
                  fit: "cover"
                }, null, 8, ["src"])
              ])
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128)),
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("div", {
            class: "banner-buttom1 shouzhi",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (routclick('CompetitionView')), ["stop"]))
          }, " 我要竞租 "),
          _createElementVNode("div", {
            class: "banner-buttom2 shouzhi",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (routclick('login')), ["stop"]))
          }, " 我要出租 ")
        ], 512), [
          [_vShow, true]
        ])
      ]),
      _: 1
    })
  ]))
}
}

})