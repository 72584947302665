import tinymce from 'tinymce'
import 'tinymce/models/dom'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/emoticons/js/emojiimages'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/image'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'
// 独立代码引入导入，不然与element plus 的汉化冲突
tinymce.addI18n('zh_cn', {
    "Redo": "重做",
    "Undo": "撤销",
    "Cut": "剪切",
    "Copy": "复制",
    "Paste": "粘贴",
    "Select all": "全选",
    "New document": "新建文档",
    "Ok": "确定",
    "Cancel": "取消",
    "Visual aids": "网格线",
    "Bold": "粗体",
    "Italic": "斜体",
    "Underline": "下划线",
    "Strikethrough": "删除线",
    "Superscript": "上标",
    "Subscript": "下标",
    "Clear formatting": "清除格式",
    "Remove": "移除",
    "Align left": "左对齐",
    "Align center": "居中对齐",
    "Align right": "右对齐",
    "No alignment": "未对齐",
    "Justify": "两端对齐",
    "Bullet list": "无序列表",
    "Numbered list": "有序列表",
    "Decrease indent": "减少缩进",
    "Increase indent": "增加缩进",
    "Close": "关闭",
    "Formats": "格式",
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.": "你的浏览器不支持打开剪贴板，请使用Ctrl+X/C/V等快捷键。",
    "Headings": "标题",
    "Heading 1": "一级标题",
    "Heading 2": "二级标题",
    "Heading 3": "三级标题",
    "Heading 4": "四级标题",
    "Heading 5": "五级标题",
    "Heading 6": "六级标题",
    "Preformatted": "预先格式化的",
    "Div": "Div",
    "Pre": "前言",
    "Code": "代码",
    "Paragraph": "段落",
    "Blockquote": "引文区块",
    "Inline": "文本",
    "Blocks": "样式",
    "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "当前为纯文本粘贴模式，再次点击可以回到普通粘贴模式。",
    "Fonts": "字体",
    "Font sizes": "字体大小",
    "Class": "类型",
    "Browse for an image": "浏览图像",
    "OR": "或",
    "Drop an image here": "拖放一张图像至此",
    "Upload": "上传",
    "Uploading image": "上传图片",
    "Block": "块",
    "Align": "对齐",
    "Default": "预设",
    "Circle": "空心圆",
    "Disc": "实心圆",
    "Square": "实心方块",
    "Lower Alpha": "小写英文字母",
    "Lower Greek": "小写希腊字母",
    "Lower Roman": "小写罗马数字",
    "Upper Alpha": "大写英文字母",
    "Upper Roman": "大写罗马数字",
    "Anchor...": "锚点...",
    "Anchor": "锚点",
    "Name": "名称",
    "ID": "ID",
    "ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "ID应该以英文字母开头，后面只能有英文字母、数字、破折号、点、冒号或下划线。",
    "You have unsaved changes are you sure you want to navigate away?": "你还有文档尚未保存，确定要离开？",
    "Restore last draft": "恢复上次的草稿",
    "Special character...": "特殊字符...",
    "Special Character": "特殊字符",
    "Source code": "源代码",
    "Insert/Edit code sample": "插入/编辑代码示例",
    "Language": "语言",
    "Code sample...": "示例代码...",
    "Left to right": "由左到右",
    "Right to left": "由右到左",
    "Title": "标题",
    "Fullscreen": "全屏",
    "Action": "动作",
    "Shortcut": "快捷方式",
    "Help": "帮助",
    "Address": "地址",
    "Focus to menubar": "移动焦点到菜单栏",
    "Focus to toolbar": "移动焦点到工具栏",
    "Focus to element path": "移动焦点到元素路径",
    "Focus to contextual toolbar": "移动焦点到上下文菜单",
    "Insert link (if link plugin activated)": "插入链接 (如果链接插件已激活)",
    "Save (if save plugin activated)": "保存(如果保存插件已激活)",
    "Find (if searchreplace plugin activated)": "查找(如果查找替换插件已激活)",
    "Plugins installed ({0}):": "已安装插件 ({0}):",
    "Premium plugins:": "优秀插件：",
    "Learn more...": "了解更多...",
    "You are using {0}": "你正在使用 {0}",
    "Plugins": "插件",
    "Handy Shortcuts": "快捷键",
    "Horizontal line": "水平分割线",
    "Insert/edit image": "插入/编辑图片",
    "Alternative description": "替代描述",
    "Accessibility": "辅助功能",
    "Image is decorative": "图像是装饰性的",
    "Source": "源",
    "Dimensions": "尺寸",
    "Constrain proportions": "保持比例",
    "General": "一般",
    "Advanced": "高级",
    "Style": "样式",
    "Vertical space": "垂直间距",
    "Horizontal space": "水平间距",
    "Border": "框线",
    "Insert image": "插入图片",
    "Image...": "图片...",
    "Image list": "图片清单",
    "Resize": "调整大小",
    "Insert date/time": "插入日期/时间",
    "Date/time": "日期/时间",
    "Insert/edit link": "插入/编辑链接",
    "Text to display": "要显示的文本",
    "Url": "地址",
    "Open link in...": "链接打开位置...",
    "Current window": "当前窗口",
    "None": "无",
    "New window": "新窗口",
    "Open link": "打开链接",
    "Remove link": "移除链接",
    "Anchors": "锚点",
    "Link...": "链接...",
    "Paste or type a link": "粘贴或输入链接",
    "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "你所填写的URL地址为邮件地址，需要加上mailto: 前缀吗？",
    "The URL you entered seems to be an external link. Do you want to add the required http:// prefix?": "你所填写的URL地址属于外部链接，需要加上http:// 前缀吗？",
    "The URL you entered seems to be an external link. Do you want to add the required https:// prefix?": "您输入的 URL 似乎是一个外部链接。您想添加所需的 https:// 前缀吗？",
    "Link list": "链接清单",
    "Insert video": "插入视频",
    "Insert/edit video": "插入/编辑视频",
    "Insert/edit media": "插入/编辑媒体",
    "Alternative source": "镜像",
    "Alternative source URL": "替代来源网址",
    "Media poster (Image URL)": "封面(图片地址)",
    "Paste your embed code below:": "将内嵌代码粘贴在下面:",
    "Embed": "内嵌",
    "Media...": "多媒体...",
    "Nonbreaking space": "不间断空格",
    "Page break": "分页符",
    "Paste as text": "粘贴为文本",
    "Preview": "预览",
    "Print": "打印",
    "Print...": "打印...",
    "Save": "保存",
    "Find": "寻找",
    "Replace with": "替换为",
    "Replace": "替换",
    "Replace all": "替换全部",
    "Previous": "上一个",
    "Next": "下一个",
    "Find and Replace": "查找和替换",
    "Find and replace...": "查找并替换...",
    "Could not find the specified string.": "未找到搜索内容。",
    "Match case": "大小写匹配",
    "Find whole words only": "全字匹配",
    "Find in selection": "在选区中查找",
    "Insert table": "插入表格",
    "Table properties": "表格属性",
    "Delete table": "删除表格",
    "Cell": "单元格",
    "Row": "行",
    "Column": "栏目",
    "Cell properties": "单元格属性",
    "Merge cells": "合并单元格",
    "Split cell": "拆分单元格",
    "Insert row before": "在上方插入行",
    "Insert row after": "在下方插入行",
    "Delete row": "删除行",
    "Row properties": "行属性",
    "Cut row": "剪切行",
    "Cut column": "剪切列",
    "Copy row": "复制行",
    "Copy column": "复制列",
    "Paste row before": "粘贴行到上方",
    "Paste column before": "粘贴此列前",
    "Paste row after": "粘贴行到下方",
    "Paste column after": "粘贴后面的列",
    "Insert column before": "在左侧插入列",
    "Insert column after": "在右侧插入列",
    "Delete column": "删除列",
    "Cols": "列",
    "Rows": "行数",
    "Width": "宽度",
    "Height": "高度",
    "Cell spacing": "单元格外间距",
    "Cell padding": "单元格内边距",
    "Row clipboard actions": "行剪贴板操作",
    "Column clipboard actions": "列剪贴板操作",
    "Table styles": "表格样式",
    "Cell styles": "单元格样式",
    "Column header": "列标题",
    "Row header": "行头",
    "Table caption": "表格标题",
    "Caption": "标题",
    "Show caption": "显示标题",
    "Left": "左",
    "Center": "居中",
    "Right": "右",
    "Cell type": "储存格别",
    "Scope": "范围",
    "Alignment": "对齐",
    "Horizontal align": "水平对齐",
    "Vertical align": "垂直对齐",
    "Top": "上方对齐",
    "Middle": "居中对齐",
    "Bottom": "下方对齐",
    "Header cell": "表头单元格",
    "Row group": "行组",
    "Column group": "列组",
    "Row type": "行类型",
    "Header": "表头",
    "Body": "表体",
    "Footer": "表尾",
    "Border color": "框线颜色",
    "Solid": "实线",
    "Dotted": "虚线",
    "Dashed": "虚线",
    "Double": "双精度",
    "Groove": "凹槽",
    "Ridge": "海脊座",
    "Inset": "嵌入",
    "Outset": "外置",
    "Hidden": "隐藏",
    "Insert template...": "插入模板...",
    "Templates": "模板",
    "Template": "模板",
    "Insert Template": "插入模板",
    "Text color": "文本颜色",
    "Background color": "背景颜色",
    "Custom...": "自定义......",
    "Custom color": "自定义颜色",
    "No color": "无",
    "Remove color": "移除颜色",
    "Show blocks": "显示区块边框",
    "Show invisible characters": "显示不可见字符",
    "Word count": "字数",
    "Count": "计数",
    "Document": "文档",
    "Selection": "选择",
    "Words": "单词",
    "Words: {0}": "字数：{0}",
    "{0} words": "{0} 字",
    "File": "文件",
    "Edit": "编辑",
    "Insert": "插入",
    "View": "查看",
    "Format": "格式",
    "Table": "表格",
    "Tools": "工具",
    "Powered by {0}": "由{0}驱动",
    "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "编辑区。按ALT-F9打开菜单，按ALT-F10打开工具栏，按ALT-0查看帮助",
    "Image title": "图片标题",
    "Border width": "边框宽度",
    "Border style": "边框样式",
    "Error": "错误",
    "Warn": "警告",
    "Valid": "有效",
    "To open the popup, press Shift+Enter": "按Shitf+Enter键打开对话框",
    "Rich Text Area": "富文本区域",
    "Rich Text Area. Press ALT-0 for help.": "编辑区。按Alt+0键打开帮助。",
    "System Font": "系统字体",
    "Failed to upload image: {0}": "图片上传失败: {0}",
    "Failed to load plugin: {0} from url {1}": "插件加载失败: {0} 来自链接 {1}",
    "Failed to load plugin url: {0}": "插件加载失败 链接: {0}",
    "Failed to initialize plugin: {0}": "插件初始化失败: {0}",
    "example": "示例",
    "Search": "搜索",
    "All": "全部",
    "Currency": "货币",
    "Text": "文字",
    "Quotations": "引用",
    "Mathematical": "数学",
    "Extended Latin": "拉丁语扩充",
    "Symbols": "符号",
    "Arrows": "箭头",
    "User Defined": "自定义",
    "dollar sign": "美元符号",
    "currency sign": "货币符号",
    "euro-currency sign": "欧元符号",
    "colon sign": "冒号",
    "cruzeiro sign": "克鲁赛罗币符号",
    "french franc sign": "法郎符号",
    "lira sign": "里拉符号",
    "mill sign": "密尔符号",
    "naira sign": "奈拉符号",
    "peseta sign": "比塞塔符号",
    "rupee sign": "卢比符号",
    "won sign": "韩元符号",
    "new sheqel sign": "新谢克尔符号",
    "dong sign": "越南盾符号",
    "kip sign": "老挝基普符号",
    "tugrik sign": "图格里克符号",
    "drachma sign": "德拉克马符号",
    "german penny symbol": "德国便士符号",
    "peso sign": "比索符号",
    "guarani sign": "瓜拉尼符号",
    "austral sign": "澳元符号",
    "hryvnia sign": "格里夫尼亚符号",
    "cedi sign": "塞地符号",
    "livre tournois sign": "里弗弗尔符号",
    "spesmilo sign": "spesmilo符号",
    "tenge sign": "坚戈符号",
    "indian rupee sign": "印度卢比",
    "turkish lira sign": "土耳其里拉",
    "nordic mark sign": "北欧马克",
    "manat sign": "马纳特符号",
    "ruble sign": "卢布符号",
    "yen character": "日元字样",
    "yuan character": "人民币元字样",
    "yuan character, in hong kong and taiwan": "元字样（港台地区）",
    "yen/yuan character variant one": "元字样（大写）",
    "Emojis": "Emojis",
    "Emojis...": "Emojis...",
    "Loading emojis...": "正在加载Emojis...",
    "Could not load emojis": "无法加载Emojis",
    "People": "人类",
    "Animals and Nature": "动物和自然",
    "Food and Drink": "食物和饮品",
    "Activity": "活动",
    "Travel and Places": "旅游和地点",
    "Objects": "物件",
    "Flags": "旗帜",
    "Characters": "字符",
    "Characters (no spaces)": "字符(无空格)",
    "{0} characters": "{0} 个字符",
    "Error: Form submit field collision.": "错误: 表单提交字段冲突。",
    "Error: No form element found.": "错误: 没有表单控件。",
    "Color swatch": "颜色样本",
    "Color Picker": "选色器",
    "Invalid hex color code: {0}": "十六进制颜色代码无效： {0}",
    "Invalid input": "无效输入",
    "R": "R",
    "Red component": "红色部分",
    "G": "G",
    "Green component": "绿色部分",
    "B": "B",
    "Blue component": "白色部分",
    "#": "#",
    "Hex color code": "十六进制颜色代码",
    "Range 0 to 255": "范围0至255",
    "Turquoise": "青绿色",
    "Green": "绿色",
    "Blue": "蓝色",
    "Purple": "紫色",
    "Navy Blue": "海军蓝",
    "Dark Turquoise": "深蓝绿色",
    "Dark Green": "深绿色",
    "Medium Blue": "中蓝色",
    "Medium Purple": "中紫色",
    "Midnight Blue": "深蓝色",
    "Yellow": "黄色",
    "Orange": "橙色",
    "Red": "红色",
    "Light Gray": "浅灰色",
    "Gray": "灰色",
    "Dark Yellow": "暗黄色",
    "Dark Orange": "深橙色",
    "Dark Red": "深红色",
    "Medium Gray": "中灰色",
    "Dark Gray": "深灰色",
    "Light Green": "浅绿色",
    "Light Yellow": "浅黄色",
    "Light Red": "浅红色",
    "Light Purple": "浅紫色",
    "Light Blue": "浅蓝色",
    "Dark Purple": "深紫色",
    "Dark Blue": "深蓝色",
    "Black": "黑色",
    "White": "白色",
    "Switch to or from fullscreen mode": "切换全屏模式",
    "Open help dialog": "打开帮助对话框",
    "history": "历史",
    "styles": "样式",
    "formatting": "格式化",
    "alignment": "对齐",
    "indentation": "缩进",
    "Font": "字体",
    "Size": "字号",
    "More...": "更多...",
    "Select...": "选择...",
    "Preferences": "首选项",
    "Yes": "是",
    "No": "否",
    "Keyboard Navigation": "键盘指引",
    "Version": "版本",
    "Code view": "代码视图",
    "Open popup menu for split buttons": "打开弹出式菜单，用于拆分按钮",
    "List Properties": "列表属性",
    "List properties...": "标题字体属性",
    "Start list at number": "以数字开始列表",
    "Line height": "行高",
    "Dropped file type is not supported": "此文件类型不支持拖放",
    "Loading...": "加载中...",
    "ImageProxy HTTP error: Rejected request": "图片代理请求错误：请求被拒绝",
    "ImageProxy HTTP error: Could not find Image Proxy": "图片代理请求错误：无法找到图片代理",
    "ImageProxy HTTP error: Incorrect Image Proxy URL": "图片代理请求错误：图片代理地址错误",
    "ImageProxy HTTP error: Unknown ImageProxy error": "图片代理请求错误：未知的图片代理错误"
})
tinymce.overrideDefaults({
  skin_url: '/tinymce/skins/ui/oxide',
  content_css: false,
  language: 'zh_cn',
  promotion: false,
  menubar: false,
  fontsize_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
  fontfamily_formats: '微软雅黑="Microsoft YaHei", 宋体="宋体", 黑体="黑体", 微软正黑="Microsoft JhengHei", 新宋体="NSimSun", 仿宋="FangSong", 楷体="KaiTi", 仿宋_GB2312="FangSong_GB2312", 楷体_GB2312="KaiTi_GB2312", Arial=arial, 宋体_GB2312="SimSun-ExtB", 楷体_GB2312="KaiTi_GB2312", 微软雅黑_GB2312="Microsoft YaHei", 黑体_GB2312="SimHei", 宋体_GBK="SimSun-GBK", 楷体_GBK="KaiTi_GBK", 微软雅黑_GBK="Microsoft YaHei", 黑体_GBK="SimHei", Arial Narrow=arial narrow',
  toolbar: [
    'undo redo | blocks fontfamily formats fontsize | removeformat bold italic forecolor backcolor underline strikethrough superscript subscript | textcolor | alignleft aligncenter alignright alignjustify | outdent indent numlist bullist | lists table image link preview fullscreen',
  ],
  forced_plugins: [
    'advlist',
    'anchor',
    'autolink',
    'autoresize',
    //'autosave',
    'charmap',
    'code',
    'codesample',
    'directionality',
    'emoticons',
    'fullscreen',
    'image',
    'importcss',
    'insertdatetime',
    'link',
    'lists',
    'media',
    'nonbreaking',
    'pagebreak',
    'preview',
    'quickbars',
    'save',
    'searchreplace',
    'table',
    'visualblocks',
    'visualchars',
    'wordcount'
  ],
  table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
  template_mdate_format: '%Y-%m-%d %H:%M:%S',
})




