<template>
  <div class="home flex-column align-center">
    <!-- 广告位 -->
    <div style="width: 100%">
      <Banner :bannerList="iconss.bannerList"></Banner>
    </div>
    <div class="homes">
      <!-- 时事新闻 -->
      <el-row :gutter="24">
        <el-col :span="16">
          <div
            class="lightgreen-box bc-fff"
            style="box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.08)"
          >
            <div class="home-content">
              <div class="content-title 8jm,">
                <!-- <div class="content-titles">时事新闻</div> -->
                <div class="tabItem active">时事新闻</div>
                <div
                  class="content-titles-more"
                  @click="routclick('mycurrentaffairs')"
                >
                  更多
                  <el-icon>
                    <ArrowRight />
                  </el-icon>
                </div>
              </div>
              <el-skeleton
                :loading="iconss.loading"
                animated
                v-if="iconss.loading"
              >
                <template #template>
                  <el-skeleton class="mt20" :rows="6" animated />
                </template>
              </el-skeleton>
              <div
                v-else-if="iconNews.iconIMG.length"
                class="flex-row mt24 flex-between shouzhi"
                @click="routePush(iconNews.iconNoId)"
              >
                <el-carousel
                  height="210px"
                  :interval="5000"
                  arrow="never"
                  style="width: 48%"
                >
                  <el-carousel-item
                    v-for="item in iconNews.iconIMG"
                    :key="item"
                    class="bg-c"
                    :style="{ backgroundImage: 'url(' + item + ')' }"
                  ></el-carousel-item>
                </el-carousel>
                <div class="flex-column rela shouzh" style="width: 49%">
                  <div class="fs20 lh30 tx-big $c-333">
                    {{ iconNews.iconN }}
                  </div>
                  <div
                    class="line-hidden lh26 fs16"
                    v-html="iconNews.iconNo"
                  ></div>
                  <div
                    class="abs"
                    @click="routePush(iconNews.iconNoId)"
                    style="bottom: 0; color: #3d4a7f"
                  >
                    查看详情>
                  </div>
                </div>
              </div>
              <div v-else style="height: 100%">
                <el-empty style="padding: 8px 0px" description="暂无资源" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8" style="padding-left: 0">
          <div
            class="orange-box bc-fff"
            style="
              box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.08);
              height: 100%;
            "
          >
            <div class="home-content">
              <div class="content-title">
                <!-- <div class="content-titles">平台通知</div> -->
                <div class="tabItem active">平台通知</div>
                <div
                  class="content-titles-more"
                  @click="routclick('Holidaynotice')"
                >
                  更多
                  <el-icon>
                    <ArrowRight />
                  </el-icon>
                </div>
              </div>

              <el-skeleton
                :loading="iconss.loading"
                animated
                v-if="iconss.loading"
              >
                <template #template>
                  <el-skeleton class="mt20" :rows="6" animated />
                </template>
              </el-skeleton>

              <div
                v-else-if="iconss.Notice.length"
                class="flex-between align-center"
                style="margin: 20px 0 0 0"
                v-for="(item, index) in iconss.Notice.slice(0, 6)"
                :key="index"
              >
                <div
                    class="dot"
                    style="
                      background-color: #d0d0d0;
                      border-radius: 50%;
                      width: 5px;
                      height: 5px;
                    "
                ></div>
                <div class="align-center" style="flex: 1;flex-wrap: nowrap;text-overflow: ellipsis;width: 0">
                  <div
                    class="mr10 lw-5 lh-5"
                    style="background-color: #d0d0d0; border-radius: 100%"
                  ></div>
                  <div
                    v-if="item.topFlag == 1"
                    class="bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
                  >
                    置顶
                  </div>
                  <div
                    class="shouzhi el-1"
                    @click="routePus(item.noticeId)"
                  >
                    {{ item.noticeTitle }}
                  </div>
                </div>
                <div style="margin-left: 20px">{{ parseTime(item.createTime, '{y}-{m}-{d}') }}</div>
              </div>
              <div v-else style="height: 100%">
                <el-empty style="padding: 8px 0px" description="暂无资源" />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 图片 -->
    <div class="homes">
      <el-row :gutter="20">
        <el-col :span="24">
          <div
            class="bc-fff"
            style="box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.08)"
          >
            <div class="home-content home-image">
              <div
                class="shouzhi"
                style="display: flex; align-items: center"
                @click="routCompetitionView2"
              >
                <img
                  style="width: 68px; height: 68px"
                  src="@/assets/image/icon_Bid.png"
                  alt
                />
                <!-- 今日竞价 -->
                <div class="mt15 mb15">
                  <div class="home-image-text fs18">今日竞价</div>
                  <div class="home-image-num fs32">
                    {{ iconss.data.biddingCount }}
                  </div>
                </div>
              </div>
              <div
                class="shouzhi"
                style="display: flex; align-items: center"
                @click="routCompetitionView(16)"
              >
                <img
                  style="width: 68px; height: 68px"
                  src="@/assets/image/icon_Released.png"
                  alt
                />
                <div>
                  <div class="home-image-text fs18">今日发布</div>
                  <div class="home-image-num fs32">
                    {{ iconss.data.publishCount }}
                  </div>
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <img
                  style="width: 68px; height: 68px"
                  src="@/assets/image/icon_Cumulative.png"
                  alt
                />
                <div>
                  <div class="home-image-text fs18">累计成交额</div>
                  <div class="home-image-num fs32">
                    {{ iconss.data.totalDealMoney }}
                    <div style="font-size: 12px; margin: 10px 0 0 10px">
                      万元
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <img
                  style="width: 68px; height: 68px"
                  src="@/assets/image/icon_Bid2.png"
                  alt
                />
                <div>
                  <div class="home-image-text fs18">累计成交单</div>
                  <div class="home-image-num fs32">
                    {{ iconss.data.totalDealCount }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 招租选择 -->
    <div class="homes">
      <el-row :gutter="20">
        <el-col :span="24">
          <div
            class="bc-fff"
            style="box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.08)"
          >
            <div class="home-content">
              <div
                class="flex-column fs32 center-block tx-big"
                style="color: #000000"
              >
                招租选择
                <div
                  style="
                    width: 64px;
                    height: 3px;
                    background: #d2a04d;
                    margin-top: 10px;
                  "
                ></div>
              </div>
              <FilterBar
                :dataList="data.screenList2"
                @change="changeQuery"
                @clearAll="clearAll"
              />
              <!-- <my-screen
              :screenList="screenList"
              :screenListIndex="screenListIndex"
              ></my-screen>-->
              <div
                id="target"
                style="
                  display: flex;
                  height: 100%;
                  justify-content: space-around;
                  width: 1400px;
                  flex-wrap: wrap;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 1350px;
                    flex-wrap: wrap;
                    margin-right: 32px;
                  "
                >
                  <div
                    class="rent-content ml15 mt20 uball shouzhi"
                    v-for="(option, index) in iconss.icon"
                    :key="index"
                    style="width: 315px; height: 320px"
                    @click="clice(option.bidIds)"
                  >
                    <!-- text -->
                    <div class="bg-c lh-180">
                      <img
                        style="width: 100%; height: 100%; object-fit: cover"
                        :src="
                          option.imgUrl
                            ? option.imgUrl
                            : require('../assets/image/icon_Cumulative2.png')
                        "
                        alt="预告标的"
                      />
                    </div>

                    <div
                      class="rent-content-title ml20 mt10 el-1"
                      @click="clice(option.bidIds)"
                    >
                      {{ option.bidName }}
                    </div>
                    <div class="rent-content-price">
                      <div class="rent-content-price1 ml20 mt5">
                        {{ option.startPrice ? option.startPrice : "-"
                        }}{{ option.priceUnitName }}
                      </div>
                      <div class="rent-content-price2 shouzhi mr20 mt5">
                        {{ option.autonomyFlag == 1 ? "查看" : "报名" }}
                      </div>
                    </div>
                    <div>
                      <div class="mt10">
                        <div class="ml20" style="color: #65686d">
                          {{ option.biddingType ? "正向报价" : "反向报价" }}
                        </div>
                        <div class="mt10 ml20" style="color: #65686d">
                          {{ option.releaseTime }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="iconss.total <= 0">
                <el-empty description="暂无数据" />
              </div>
              <div class="mt20 tx-c">
                <div style="color: #3d4a7f" v-if="iconss.zhankai">
                  <span class="plr20 shouzhi" @click="Loadmore">展开更多</span>
                  <span
                    v-if="iconss.current > 1"
                    class="plr20 shouzhi"
                    @click="suohui"
                    >收起</span
                  >
                </div>
                <div
                  style="color: #3d4a7f"
                  v-else-if="!iconss.zhankai && iconss.current != 1"
                >
                  <span class="plr20 shouzhi" @click="suohui">收起</span>
                </div>
                <!-- <div style="color: #3d4a7f" v-else>无更多数据了</div> -->
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 成交公告 -->
    <div class="homes">
      <el-row :gutter="20">
        <el-col :span="12" style="padding-right: 6px">
          <div
            style="
              box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.08);
              background: #ffffff;
              height: 335px;
            "
          >
            <div class="home-content">
              <div class="content-title">
                <button
                  class="tabItem fs18"
                  :class="{ active: notice_ids.notice_id == 1 }"
                  @click="gongclick(1)"
                >
                  成交公告
                </button>
                <button
                  class="tabItem fs18"
                  :class="{ active: notice_ids.notice_id == 2 }"
                  @click="gongclick(2)"
                >
                  变更公告
                </button>
                <button
                  class="tabItem fs18"
                  :class="{ active: notice_ids.notice_id == 3 }"
                  @click="gongclick(3)"
                >
                  异常公告
                </button>
                <div class="content-titles-more" @click="routclick('signup')">
                  更多
                  <el-icon>
                    <ArrowRight />
                  </el-icon>
                </div>
              </div>
              <div v-show="notice_ids.notice_id == 1">
                <div v-if="iconss.NoTi.length">
                  <div
                    class="flex-between align-center shouzhi"
                    style="margin: 20px 0 0 0"
                    v-for="(item, index) in iconss.NoTi.slice(0, 6)"
                    :key="index"
                  >
                    <div class="align-center">
                      <div
                        style="
                          background-color: #d0d0d0;
                          border-radius: 100%;
                          width: 5px;
                          height: 5px;
                          margin-right: 10px;
                        "
                      ></div>
                      <div
                        v-if="item.topFlag == 1"
                        class="bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
                      >
                        置顶
                      </div>
                      <div
                        class="shouzhi el-1 lw-400"
                        @click="routclickID(item.noticeId)"
                      >
                        {{ item.noticeTitle }}
                      </div>
                    </div>
                    <div>{{ parseTime(item.createTime, '{y}-{m}-{d}') }}</div>
                  </div>
                </div>
                <div v-else style="height: 100%">
                  <el-empty style="padding: 8px 0px" description="暂无资源" />
                </div>
              </div>
              <!-- 变更公告 -->
              <div v-show="notice_ids.notice_id == 2">
                <div v-if="iconss.RenNo.length">
                  <div
                    class="shouzhi flex-between align-center"
                    style="margin: 20px 0 0 0"
                    v-for="(item, index) in iconss.RenNo.slice(0, 6)"
                    :key="index"
                  >
                    <div class="align-center">
                      <div
                        style="
                          background-color: #d0d0d0;
                          border-radius: 100%;
                          width: 5px;
                          height: 5px;
                          margin-right: 10px;
                        "
                      ></div>
                      <div
                        v-if="item.topFlag == 1"
                        class="bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
                      >
                        置顶
                      </div>
                      <div
                        class="shouzhi el-1 lw-400"
                        @click="routclickID(item.noticeId)"
                      >
                        {{ item.noticeTitle }}
                      </div>
                    </div>
                    <div>{{ parseTime(item.createTime, '{y}-{m}-{d}') }}</div>
                  </div>
                </div>
                <div v-else style="height: 100px">
                  <el-empty style="padding: 8px 0px" description="暂无资源" />
                </div>
              </div>

              <!-- 其他公告 -->
              <!-- text -->
              <div v-show="notice_ids.notice_id == 3">
                <div v-if="iconss.ErNo.length">
                  <div
                    class="flex-between align-center shouzhi"
                    style="margin: 20px 0 0 0"
                    v-for="(item, index) in iconss.ErNo.slice(0, 6)"
                    :key="index"
                  >
                    <div class="align-center">
                      <div
                        style="
                          background-color: #d0d0d0;
                          border-radius: 100%;
                          width: 5px;
                          height: 5px;
                          margin-right: 10px;
                        "
                      ></div>
                      <div
                        v-if="item.topFlag == 1"
                        class="bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
                      >
                        置顶
                      </div>
                      <div
                        class="shouzhi el-1 lw-400"
                        @click="routclickID(item.noticeId)"
                      >
                        {{ item.noticeTitle }}
                      </div>
                    </div>
                    <div>{{ parseTime(item.createTime, '{y}-{m}-{d}') }}</div>
                  </div>
                </div>
                <div v-else style="height: 100%">
                  <el-empty style="padding: 8px 0px" description="暂无资源" />
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <!-- 商机无限 -->
        <el-col :span="12" style="padding-left: 6px">
          <div
            style="
              box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.08);
              background: #ffffff;
              height: 100%;
            "
          >
            <div class="home-content">
              <div class="content-title">
                <!-- <div class="content-titles">商机无限</div> -->
                <div class="tabItem active">商机无限</div>
                <div
                  class="content-titles-more"
                  @click="routclick('businessopportunity')"
                >
                  更多
                  <el-icon>
                    <ArrowRight />
                  </el-icon>
                </div>
              </div>
              <!-- text21 -->
              <div v-if="iconss.sj.length">
                <div
                  class="shouzhi flex-between align-center"
                  style="margin: 20px 0 0 0"
                  v-for="(item, index) in iconss.sj.slice(0, 6)"
                  :key="index"
                >
                  <div class="align-center">
                    <div
                      style="
                        background-color: #d0d0d0;
                        border-radius: 100%;
                        width: 5px;
                        height: 5px;
                        margin-right: 10px;
                      "
                    ></div>
                    <div
                      v-if="item.topFlag == 1"
                      class="bc-f0a lh-20 lh20 lw-40 uc-5 c-fff tx-c mr6"
                    >
                      置顶
                    </div>
                    <div
                      class="shouzhi el-1 lw-400"
                      style="line-height: 19px"
                      @click="routclickSJ(item.newsIds)"
                    >
                      {{ item.title }}
                    </div>
                  </div>
                  <div>{{ parseTime(item.createTime, '{y}-{m}-{d}') }}</div>
                </div>
              </div>
              <div v-else style="height: 100%">
                <el-empty style="padding: 8px 0px" description="暂无资源" />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 交易日历 -->
    <div class="homes">
      <el-row :gutter="20">
        <el-col :span="24">
          <div
            style="
              box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.08);
              background: #ffffff;
            "
          >
            <div class="home-contentb">
              <div
                class="flex-column fs32 center-block tx-big"
                style="color: #000000"
              >
                交易日历
                <div
                  style="
                    width: 64px;
                    height: 3px;
                    background: #d2a04d;
                    margin-top: 10px;
                  "
                ></div>
              </div>
              <div class="signup">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <div class="home-contenta">
                      <el-config-provider>
                        <div class="lwbox">
                          <!-- :range="iconss.dataArray" -->
                          <el-calendar v-model="calendarvalue">
                            <template #date-cell="{ data }">
                              <div @click="dayClick(data)">
                                <p
                                  class="data_a tx-c fs24"
                                  :class="data.isSelected ? 'is-selected' : ''"
                                  style="transform: translateY(5px)"
                                >
                                  {{ data.day.split("-").slice(1)[1] }}
                                </p>
                                <div
                                  class="tx-r c-c08 pb10"
                                  v-for="(item, index) in textContent(data.day)"
                                  :key="index"
                                >
                                  {{ item.count }}单
                                </div>
                              </div>
                            </template>
                          </el-calendar>
                        </div>
                      </el-config-provider>
                    </div>
                    <div id="target1" class="wrap plr20">
                      <div class="wrap" v-if="iconss.rili.length">
                        <div class="riliList wrap flex-between">
                          <div
                            @click="clice(option.bidIds)"
                            class="mt20 uball lw-640 lh-180 lwbox shouzhi"
                            v-for="(option, index) in iconss.rili"
                            :key="index"
                          >
                            <!-- text -->
                            <div class="bg-c lh-180 lw-200">
                              <img
                                style="
                                  width: 100%;
                                  height: 100%;
                                  object-fit: cover;
                                "
                                :src="option.imgUrl"
                                alt
                              />
                            </div>
                            <div
                              class="mt5 ml10 mr5"
                              style="flex-direction: column"
                            >
                              <div class="fs19 mt5 tx-big el-2">
                                {{ option.bidName }}
                              </div>
                              <div class="mt5 c-999">
                                竞价日期： {{ option.biddingDate }}
                              </div>
                              <div class="mt7 c-999">
                                {{ option.startPrice ? option.startPrice : "-"
                                }}{{ option.priceUnitName }}
                              </div>
                              <div class="mt10 c-999">
                                浏览次数：{{ option.views }}
                              </div>
                              <div class="mt10 lwbox">
                                <button
                                  class="c-c08 lh-30 fs12 lw-100 uc-3"
                                  style="border: 1px solid #c08c58"
                                >
                                  {{ option.presentStatusName }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pagination">
                          <!-- 分页选择 -->
                          <div class="mb20 mt20">
                            <el-pagination
                              small
                              background
                              layout="total, sizes, prev, pager, next, jumper, ->"
                              class="mt-4 mt10"
                              v-model:page-size="pageSize"
                              :page-sizes="[4]"
                              :total="iconss.rili_total"
                              @current-change="currentPage"
                              :current-page="calendarPage"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mt20 tx-c flex-center" v-else>
                        <div class="tx-c" style="color: #3d4a7f">
                          无更多数据了
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 竞价时事 -->

    <div class="dahezi bg-c mt12">
      <div class="homes">
        <div class="fs32 center-block flex-column tx-big" style="color: #fff">
          竞价时事
          <div class="mt10 bc-fff" style="width: 64px; height: 3px"></div>
        </div>
        <el-row :gutter="20">
          <el-col :span="24">
            <div
              class="mb60 bc-fff"
              style="box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.08)"
            >
              <div class="table-container mt20">

<!--                <el-table-->
<!--                    :data="iconss.Icon"-->
<!--                    align="left"-->
<!--                    :header-cell-style="{-->
<!--                          background: '#B97F47',-->
<!--                          color: '#fff',-->
<!--                          height: '64px',-->
<!--                         }"-->
<!--                >-->
<!--                  <el-table-column prop="xuhao" label="标的编号" width="160px">-->
<!--                    <template #default="scope">-->
<!--                      <div class="dateCount fs16 lh60" style="height: 60px">-->
<!--                        {{ scope.row.bidIds }}-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column prop="name" label="标的名称">-->
<!--                    <template #default="scope">-->
<!--                      <div-->
<!--                          class="fs16 shouzhi tx-dec"-->
<!--                          @click="clice(scope.row.bidIds)"-->
<!--                      >-->
<!--                        {{ scope.row.bidName }}-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column prop="moudel" label="报价模式" width="120px">-->
<!--                    <template #default="scope">-->
<!--                      <div class="fs16">-->
<!--                        {{-->
<!--                          scope.row.biddingType == 0 ? "正向报价" : "反向报价"-->
<!--                        }}-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column prop="num" label="挂牌价" width="120px">-->
<!--                    <template #default="scope">-->
<!--                      <div class="fs16">{{ scope.row.startPrice }}</div>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column prop="num" label="价格单位" width="120px">-->
<!--                    <template #default="scope">-->
<!--                      <div class="fs16">-->
<!--                        {{ scope.row.priceUnitName }}-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column-->
<!--                      prop="status"-->
<!--                      label="报名结束时间"-->
<!--                      width="190px"-->
<!--                  >-->
<!--                    <template #default="scope">-->
<!--                      <div class="dateCount fs16 lh60" style="height: 60px">-->
<!--                        {{ scope.row.registrationEndTime }}-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column-->
<!--                      prop="address"-->
<!--                      label="竞价状态"-->
<!--                      width="160px"-->
<!--                  >-->
<!--                    <template #default="scope">-->
<!--                      <el-button class="addressCount ub0 mouse" size="small">-->
<!--                        <div class="fs16">-->
<!--                          {{-->
<!--                            scope.row.biddingStatusName-->
<!--                                ? scope.row.biddingStatusName-->
<!--                                : scope.row.progressName-->
<!--                          }}-->
<!--                        </div>-->
<!--                      </el-button>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                </el-table>-->

                <div class="bidding-wrapper">
                  <div class="bidding-header">
                    <div class="cell-title">标的编号</div>
                    <div class="cell-title">标的名称</div>
                    <div class="cell-title">报价模式</div>
                    <div class="cell-title">挂牌价</div>
                    <div class="cell-title">价格单位</div>
                    <div class="cell-title">报名结束时间</div>
                    <div class="cell-title">竞价状态</div>
                  </div>
                  <div class="bidding-content-wrapper" ref="listWrap" >
                    <div class="bidding-item" v-for="(item, index) in iconss.Icon" :key="index">
                      <div class="cell-value">{{ item.bidIds }}</div>
                      <div class="cell-value" @click="clice(item.bidIds)">{{ item.bidName }}</div>
                      <div class="cell-value">{{ item.biddingType == 0 ? "正向报价" : "反向报价" }}</div>
                      <div class="cell-value">{{ item.startPrice }}</div>
                      <div class="cell-value">{{ item.priceUnitName }}</div>
                      <div class="cell-value">{{ item.registrationEndTime }}</div>
                      <div class="cell-value">
                        <el-button class="addressCount ub0 mouse" size="small">
                          {{ item.biddingStatusName
                            ? item.biddingStatusName
                            : item.progressName }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>




                <div class="tableBtn">
                  <button
                    class="bc-fff lw-128 lh-40 uc-4"
                    style="border: 1px solid #d0d1d4"
                    @click="routclick('biddingHall')"
                  >
                    前往竞价
                  </button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="homes">
      <el-row :gutter="20">
        <el-col :span="24">
          <div style>
            <div class>
              <div
                class="fs32 center-block flex-column tx-big"
                style="color: #000000"
              >
                合作伙伴
                <div
                  style="
                    width: 64px;
                    height: 3px;
                    background: #d2a04d;
                    margin-top: 10px;
                  "
                ></div>
              </div>
              <div class="img_container">
                <div
                  class="center-block tx-c lw-320 lh-118 bc-fff"
                  style="box-shadow: rgb(0 0 0 / 8%) 2px 3px 6px 1px"
                >
                  <img src="@/views/images/img_logo1@2x.png" alt />
                </div>
                <div
                  class="center-block tx-c lw-320 lh-118 bc-fff"
                  style="box-shadow: rgb(0 0 0 / 8%) 2px 3px 6px 1px"
                >
                  <img src="@/views/images/img_logo2@2x.png" alt />
                </div>
                <div
                  class="center-block tx-c tx-c lw-320 lh-118 bc-fff"
                  style="box-shadow: rgb(0 0 0 / 8%) 2px 3px 6px 1px"
                >
                  <img src="@/views/images/img_logo1@2x.png" alt />
                </div>
                <div
                  class="center-block tx-c tx-c lw-320 lh-118 bc-fff"
                  style="box-shadow: rgb(0 0 0 / 8%) 2px 3px 6px 1px"
                >
                  <img src="@/views/images/img_logo2@2x.png" alt />
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 侧边栏 -->
    <FixedMenu></FixedMenu>
    <!-- 客服 -->
    <botUI></botUI>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch, toRefs, nextTick, onUnmounted } from "vue";
import { BiCom, queryDict, getNonLoginPageList } from "@/api/competition";
import { ElMessage, ElMessageBox } from "element-plus";
import FilterBar from "@/components/FilterBar";
import Banner from "@/components/Banner";
import { homeList, getCalDetailedList } from "@/api/home";
import { getCalList } from "@/api/test";
import { getTokenStatus } from "@/api/competition";
import { useRoute, useRouter } from "vue-router";
import dataFormat from "dateformat";
import FixedMenu from "@/components/fixedMenu";
import botUI from "@/components/botUI";
import { parseTime } from '@/util/utils'
let route = useRoute();
let router = useRouter();
const listWrap = ref<HTMLElement>()

let screenListIndex = reactive([0, 0, 0, 0, 0, 0]);
let calendarvalue = ref(dataFormat(new Date(), "yyyy-mm-dd"));
let notice_ids = reactive({
  notice_id: 1,
});
//交易日历 默认分页数
const pageSize = ref(4);
//列表数据
let iconss: any = reactive({
  loading: true,
  bannerList: [],
  importanceNotice: {},
  sj: [],
  NoTi: [],
  NoId: [],
  icon: [],
  ErNo: [],
  Icon: [],
  RenNo: [],
  Title: [],
  Notice: [],
  aajinjia: [],
  data: {},
  region: "",
  bidType: "",
  themeQuery: "",
  bidTimeQuery: "",
  rentTypeQuery: "",
  bidPriceQuery: "",
  hemeQueryArray: [],
  rentTypeQueryArray: [],
  bidTypeArray: [],
  cityArray:[],
  regionArray: [],
  total: 1,
  current: 1,
  zhankai: true,
  rili: [],
  rili_total: "",
  sizes: [4],
  //日历周期
  dataArray: [
    new Date(new Date().getTime()),
    new Date(new Date().getTime() + 604800000),
  ],
  //月视图
  dataArray2: [
    new Date(new Date().getTime()),
    new Date(new Date().getTime() + 2626560000),
  ],
  calendarData: [],
  //当前选中的是哪一天的日历
  calendarDay: "",
});
const calendarPage = ref(1);
const calendarPageSize = ref(4);
let calendarDay: string | null = null;
onMounted(() => {
  getNonLoginPageListFN();
  bicomfn();
  homeListFN();
  queryDictFN();
  getCalListFN(dataFormat(new Date(), "yyyy-mm-dd hh:mm:ss"));
  calendarDay = dataFormat(new Date(), "yyyy-mm-dd hh:mm:ss");
  getCalDetailedListFN(dataFormat(new Date(), "yyyy-mm-dd hh:mm:ss"));
  //用来判断tonken是否失效
  getTokenStatus({}).then((res: any) => {});
});
let scrolltimer = null
onUnmounted(() => {
  clearInterval(scrolltimer)
})
const homeListFN = async () => {
  homeList().then((res) => {
    if (res.code == 200) {
      iconss.loading = false;
      // console.log("res.data:", res.data);
      // 新闻详情
      // console.log(res);

      if (res.data.news != null) {
        iconNews.iconN = res.data.news.title;
        iconNews.iconNo = res.data.news.details;
        iconNews.iconNoId = res.data.news.newsIds;
        iconNews.iconIMG.push(res.data.news.url);
      }
      //iconNews.iconIMG = res.data.news[0].imgUrl

      // 平台通知
      if (res.data.otherNoticeList.length) {
        iconss.Notice = res.data.otherNoticeList;
      }
      if (res.data.otherNoticeList.length) {
        iconss.NoId = res.data.otherNoticeList[0].noticeId;
      }

      //重要公告
      if (
        res.data.importanceNotice && res.data.importanceNotice.noticeContent &&
        res.data.importanceNotice.noticeContent.length
      ) {
        iconss.importanceNotice.noticeContent =
          res.data.importanceNotice.noticeContent;
        if (window.sessionStorage.getItem("isread") == "true") {
          // console.log(window.sessionStorage.getItem("isread"))
        } else {
          ElMessageBox.alert(
            iconss.importanceNotice.noticeContent,
            res.data.importanceNotice.noticeTitle,
            {
              dangerouslyUseHTMLString: true,
              center: true,
              customClass: 'myCustomClass',
              callback: () => {
                window.sessionStorage.setItem("isread", "true");
              },
            }
          );
        }
      }

      //成交公告
      iconss.NoTi = res.data.winNoticeList;
      //变更公告
      if (res.data && res.data.changeNoticeList) {
        iconss.RenNo = res.data.changeNoticeList;
      }
      //其他公告
      iconss.ErNo = res.data.errorNoticeList;
      //商机无限
      iconss.sj = res.data.advertisement;
      //成交数据
      iconss.data = res.data;
    }
  });
};
const clearAll = () => {
  let arr: any = [];
  let id = 1;
  data.screenList2.forEach((e: any) => {
    if (e.dictData.length) {
      e.dictData.forEach((v: any, i: number) => {
        id++;
        v.id = id;
        if (i == 0) {
          v.checked = true;
        } else {
          v.checked = false;
        }
      });
    }
    if (
      e.type == "bidTypeArray" ||
      e.type == "cityArray" ||
      e.type == "regionArray" ||
      e.type == "bidPriceQuery" ||
      e.type == "rentTypeQueryArray" ||
      e.type == "themeQueryArray" ||
      e.type == "bidTimeQuery"
    ) {
      arr.push(e);
    }
  });
  data.screenList2 = arr;
};
const dayClick = (item: any) => {
  // console.log(item);
  calendarDay = item.day + " 00:00:00";
  calendarPage.value = 1;
  let day = item.day + " 00:00:00";
  //iconss.calendarData = day;
  getCalDetailedListFN(day);
};
const currentPage = (e) => {
  // console.log(e)
  calendarPage.value = e;
  let day = iconss.calendarData;
  // console.log(day);
  getCalDetailedListFN(calendarDay);
};
//交易日历
const getCalListFN = (date: any) => {
  getCalList({
    date: date,
  }).then((res) => {
    if (res.code == 200) {
      iconss.calendarData = res.data;
      //getCalDetailedListFN(date);
    }
  });
};
//日历详情
const getCalDetailedListFN = (date: any) => {
  getCalDetailedList({
    pageNum: calendarPage.value,
    pageSize: calendarPageSize.value,
    biddingDate: date,
  }).then((res: any) => {
    if (res.code == 200) {
      if (calendarPage.value === 1) {
        iconss.rili = res.data.records;
      } else {
        iconss.rili = res.data.records;
        //iconss.rili = iconss.rili.concat(res.data.records);
      }
      iconss.rili_total = res.data.total;
    }
  });
};
//新闻数据
let iconNews:any = reactive({
  iconN: [],
  iconNo: [],
  iconIMG: [],
  iconNoId: [],
  total: 1,
});
//分页
const data = reactive({
  screenList2: [] as any[],
  endBidTime: "",
  bidEndPrice: undefined,
  beginBidTime: "",
  bidStartPrice: undefined,
  text: "",
  page: {
    current: 1,
    size: 4,
    total: 4,
    sizes: [4],
  },
  fromData: {
    timeStart: [
      {
        toISOString: () => {
          return "";
        },
      },
      {
        toISOString: () => {
          return "";
        },
      },
    ],
  },
});

//watch监听
watch(screenListIndex, (newValue, oldValue) => {
  iconss.current = 1;
  iconss.icon = [];
  iconss.bidType = data.screenList2[0].dictData[newValue[0]].dictValue;
  iconss.region = data.screenList2[1].dictData[newValue[1]].dictValue;
  iconss.bidPriceQuery = data.screenList2[2].dictData[newValue[2]].dictValue;
  iconss.rentTypeQuery = data.screenList2[3].dictData[newValue[3]].dictValue;
  iconss.themeQuery = data.screenList2[4].dictData[newValue[4]].dictValue;
  iconss.bidTimeQuery = data.screenList2[5].dictData[newValue[5]].dictValue;
  BiComFN(
    iconss.region,
    iconss.bidType,
    iconss.themeQuery,
    iconss.bidTimeQuery,
    iconss.bidPriceQuery,
    iconss.rentTypeQuery
  );
});
watch(calendarvalue, (newValue, oldValue) => {
  //console.log(calendarvalue)
  let check_new = dataFormat(newValue, "yyyy-mm");
  let check_old = dataFormat(oldValue, "yyyy-mm");
  if (
    check_new != check_old ||
    dataFormat(new Date(), "yyyy-mm-dd") == dataFormat(newValue, "yyyy-mm-dd")
  ) {
    // console.log(dataFormat(newValue, "yyyy-mm"));
    // console.log(dataFormat(oldValue, "yyyy-mm"));
    let time = dataFormat(newValue, "yyyy-mm-dd");
    let time2 = dataFormat(newValue, "yyyy-mm-dd hh:mm:ss");
    getCalListFN(time);
    //重置页码
    calendarPage.value = 1;
    getCalDetailedListFN(time2);
  }
});

const textContent = (date: any) => {
  return iconss.calendarData.filter((item: any) => {
    return date === item.current;
  });
};
//条件筛选列表
const queryDictFN = () => {
  queryDict().then((res) => {
    if (res.code == 200) {
      let arr: any = [];
      let id = 1;
      res.data.forEach((e: any) => {
        if (e.dictData.length) {
          e.dictData.forEach((v: any, i: number) => {
            id++;
            v.id = id;
            if (i == 0) {
              v.checked = true;
            } else {
              v.checked = false;
            }
          });
        }
        if (
          e.type == "bidTypeArray" ||
          e.type == "cityArray" ||
          e.type == "regionArray" ||
          e.type == "bidPriceQuery" ||
          e.type == "rentTypeQueryArray" ||
          e.type == "themeQueryArray" ||
          e.type == "bidTimeQuery"
        ) {
          arr.push(e);
        }
      });
      let newElement = {
        name: "标的区域",
        type: "regionArray",
        dictData: [
          {
            dictValue: "-1",
            dictName: "全部",
            checked: true
          },
        ]
      }
      arr.splice(2, 0, newElement)
      data.screenList2 = arr;
    }
  });
};
//加载更多
const Loadmore = () => {
  iconss.current++;
  getNonLoginPageListFN();
};
//收起
const suohui = () => {
  iconss.current = 1;
  getNonLoginPageListFN();
  document.getElementById("target").scrollIntoView();
};
// const currentChange = (e) => {};
// const sizeChange = (e) => {};

//时间倒计时
const onStart = (time: any, index: any) => {
  // alert(type)
  let intervalId = setInterval(() => {
    let date: any = new Date(time).getTime();
    // let stopAt: any =
    //   new Date(date)
    //     .toLocaleString("chinese", { hour12: false })
    //     .split("/")
    //     .join("-") || 0;
    // if (stopAt) {
    //   stopAt = stopAt.replace(/-/g, "/");
    // }
    let stopAt = new Date(date).getTime() / 1000;
    let now: any = new Date().getTime() / 1000;
    let leftTime: number = parseInt(stopAt) - parseInt(now);

    if (leftTime < 0) {
      clearInterval(intervalId);
      var seconds: any = 0;
      iconss.Icon[index].registrationEndTime = `0天0小时0分钟0秒`;
      return;
    }
    //alert(9999)
    if (leftTime < 86400) {
      var hours: any = parseInt((leftTime / 3600).toString(), 10);
      var days: any = 0;
    } else {
      var days: any = parseInt((leftTime / 86400).toString(), 10);
      var hours: any = parseInt(((leftTime / 3600) % 24).toString(), 10);
    }
    var minutes: any = parseInt(((leftTime / 60) % 60).toString(), 10);
    var seconds: any = parseInt((leftTime % 60).toString(), 10);
    //console.log(`${days}天${hours}小时${minutes}分钟${seconds}秒`)
    //days + hours + minutes + seconds;
    iconss.Icon[
      index
    ].registrationEndTime = `${days}天${hours}小时${minutes}分钟${seconds}秒`;
    //return `${days}天${hours}小时${minutes}分钟${seconds}秒`
  }, 1000);
};

//分页查询标的
const getNonLoginPageListFN = async () => {
  await getNonLoginPageList({
    pageSize: 8,
    region: iconss.region,
    bidType: iconss.bidType,
    queryProgressType: 14,
    pageNum: iconss.current,
    bidEndPrice: data.bidEndPrice,
    bidStartPrice: data.bidStartPrice,
    bidTypeArray: iconss.bidTypeArray,
    regionArray: iconss.regionArray,
    cityArray:iconss.cityArray,
    rentTypeQueryArray: iconss.rentTypeQueryArray,
    themeQueryArray: iconss.themeQueryArray,
    bidIdsOrName: route.query.bidIds ? route.query.bidIds : "",
    bidPriceQuery: iconss.bidPriceQuery + "",
    bidTimeQuery: iconss.bidTimeQuery + "",
    beginBidTime: data.beginBidTime ? dataFormatfn(data.beginBidTime) : "",
    endBidTime: data.endBidTime ? dataFormatfn(data.endBidTime) : "",
    bidCodeOrName: data.text,
  }).then((data) => {
    if (iconss.current == 1) {
      iconss.icon = data.data.records;
    } else {
      iconss.icon.push(...data.data.records);
    }
    // iconss.icon.push(data.data.records);
    // console.log(iconss.icon);
    if (data.data.pages > iconss.current) {
      iconss.zhankai = true;
    } else {
      iconss.zhankai = false;
    }
    iconss.total = data.data.total;

    // console.log("===========", iconss.icon, iconss.icon.length);
  });
};

const BiComFN = async (
  region: any,
  bidType: any,
  themeQuery: any,
  bidTimeQuery: any,
  rentTypeQuery: any,
  bidPriceQuery: any
) => {
  await BiCom({
    pageSize: 8,
    region: iconss.region,
    bidType: iconss.bidType,
    queryProgressType: 14,
    themeQuery: iconss.themeQuery,
    pageNum: iconss.current,
    bidTimeQuery: iconss.bidTimeQuery,
    bidPriceQuery: iconss.bidPriceQuery,
    rentTypeQuery: 1,
    bidEndPrice: data.bidEndPrice,
    bidStartPrice: data.bidStartPrice,
    bidTypeArray: iconss.bidTypeArray,
    regionArray: iconss.regionArray,
    rentTypeQueryArray: iconss.rentTypeQueryArray,
    themeQueryArray: iconss.themeQueryArray,
    bidIdsOrName: route.query.bidIds ? route.query.bidIds : "",
    beginBidTime: data.fromData.timeStart
      ? dataFormatfn(data.fromData.timeStart[0])
      : "",
    endBidTime: data.fromData.timeStart
      ? dataFormatfn(data.fromData.timeStart[1])
      : "",
  }).then((data) => {
    if (iconss.current == 1) {
      iconss.icon = data.data.records;
    } else {
      iconss.icon.push(...data.data.records);
    }
    // iconss.icon.push(data.data.records);
    console.log(iconss.icon);
    if (data.data.pages > iconss.current) {
      iconss.zhankai = true;
    } else {
      iconss.zhankai = false;
    }
    iconss.total = data.data.total;
  });
};




//交易日历
const a = new Date();
console.log(a);
var day = a.getDay(); // 获取星期几
var date = a.getDate(); //获取当前日
var month = a.getMonth(); //获取月 未加一
var year = a.getFullYear(); //获取年
var arr = [];
// var object = {
//   date: "",
//   week: ""
// }
for (let i = 0; i < 7; i++) {
  var d = new Date(year, month, date);
  var m: any = d.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var r: any = d.getDate();
  r = r < 10 ? +r : r;
  var monthdate = r;
  var week: any = d.getDay();

  if (week == day) {
    week = "今天";
  } else if (week == day + 1 || week == day - 6) {
    week = "明天";
  }
  switch (week) {
    case 0:
      week = "周日";
      break;
    case 1:
      week = "周一";
      break;
    case 2:
      week = "周二";
      break;
    case 3:
      week = "周三";
      break;
    case 4:
      week = "周四";
      break;
    case 5:
      week = "周五";
      break;
    case 6:
      week = "周六";
      break;
  }
  arr.push({ monthdate, week });
  date++;
}

const dataFormatfn = (time: any) => {
  if (time == null || time == "" || !time.getDate) {
    return "";
  }
  return dataFormat(time, "yyyy-mm-dd HH:mm:ss");
};

const changeQuery = (e: any) => {
  console.log('接收到参数',e);
  iconss.current = 1;
  iconss.themeQueryArray = e.themeQueryArray;
  iconss.rentTypeQueryArray = e.rentTypeQueryArray;
  iconss.bidTypeArray = e.bidTypeArray;
  iconss.regionArray = e.regionArray;
  iconss.bidTimeQuery = e.bidTimeQuery;
  iconss.bidPriceQuery = e.bidPriceQuery;
  iconss.cityArray = e.cityArray;
  data.bidEndPrice = e.bidEndPrice;
  data.bidStartPrice = e.bidStartPrice;
  data.beginBidTime = e.beginBidTime;
  data.endBidTime = e.endBidTime;
  data.text = e.bidCodeOrName;
  getNonLoginPageListFN();
};

//首页时事新闻跳转详情
const routePush = (item: any) => {
  let url = router.resolve({
    path: "/Newsdetail",
    query: { id: item },
  });
  window.open(url.href);
};
//首页时事新闻加载更多
const routePus = (item: any) => {
  let url = router.resolve({
    path: "/NoticeDetails",
    query: {
      id: item,
    },
  });
  window.open(url.href);
};
//首页公告跳转详情
const routclickID = (item: any) => {
  let url = router.resolve({
    path: "/Transactiondetails",
    query: {
      bidIds: item,
    },
  });
  window.open(url.href);
};
//公告变更
let gongclick = function (num: any) {
  notice_ids.notice_id = num;
};
//首页商机无限跳转详情
const routclickSJ = (item: any) => {
  let url = router.resolve({
    path: "/PlatformNoti",
    query: {
      id: item,
    },
  });
  window.open(url.href);
};
//首页招租商机跳转
const clice = (item: any) => {
  let url = router.resolve({
    path: "/Mydetails",
    query: {
      bidIds: item,
    },
  });
  window.open(url.href);
};
//跳转标的列表
const routCompetitionView = (res: number | string) => {
  let url = router.resolve({
    path: "/CompetitionView",
    query: {
      queryProgressType: res,
    },
  });
  window.open(url.href);
};
//跳转今日竞价
const routCompetitionView2 = (res: number | string) => {
  let url = router.resolve({
    path: "/biddingHall",
  });
  window.open(url.href);
};
//带上搜索的的时候的标的列表
const BiComFNS = () => {
  BiComFN(
    iconss.region,
    iconss.bidType,
    iconss.themeQuery,
    iconss.bidTimeQuery,
    iconss.bidPriceQuery,
    iconss.rentTypeQuery
  );
};
const bicomfn = async () => {
  const { data } = await BiCom({
    pageNum: 1,
    pageSize: 1000,
    queryProgressType: 20,
  });
  iconss.Icon = data.records;
  for (let i = 0; i < iconss.Icon.length; i++) {
    onStart(iconss.Icon[i].registrationEndTime, i);
  }
  iconss.total = data.total;


  await nextTick(() => {
    console.log(listWrap.value)
    if(!listWrap.value && scrolltimer) {
      clearInterval(scrolltimer)
    }
    scrolltimer = setInterval(() => {
      // 元素自增距离顶部1像素

      listWrap.value!.scrollTop += 1;
      // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      if (
          listWrap.value!.clientHeight + listWrap.value!.scrollTop >=
          listWrap.value!.scrollHeight - 1
      ) {
        // 回到第一条数据
        listWrap.value!.scrollTop = 0;
      }
    }, 150); // 滚动速度
  });
};
const routclick = (res: number | string) => {
  let url = router.resolve(`/${res}`);
  window.open(url.href);
};
//标的信息输入框
let Bjinput = ref([]);
defineExpose({
  ...toRefs(data),
  bicomfn,
  Loadmore,
  BiComFNS,
  routePush,
  routclick,
  getCalListFN,
  getCalDetailedListFN,
  textContent,
  routCompetitionView,
  routCompetitionView2,
});
</script>
<style lang="scss" scoped>
// .jingjia {
//   background-image: url("@/views/images/img_bg.png");
// }

:deep .el-calendar-table .el-calendar-day:hover {
  background-color: #faecd8;
}

:deep .el-calendar {
  --el-calendar-selected-bg-color: #faecd8;
}

.img_container {
  padding: 20px 0 !important;
  display: flex;
  justify-content: space-between;
  opacity: 1;
  border-radius: 8px;

  & > img:nth-of-type(2n-1) {
    width: 259px;
    height: 54px;
  }

  & > img:nth-of-type(2n) {
    width: 255px;
    height: 68px;
  }
}

.table-container {
  .dateCount {
    background: transparent;
    color: red;
  }

  .addressCount {
    background: transparent;
    color: #c08c58;
    background: #f9eedd;
  }

  .tableBtn {
    background: #fff;
    text-align: center;
    padding: 15px 0;

    button:hover {
      background: #c08c58;
      color: #fff;
    }
  }
}

.home {
  min-width: 1400px;
  background: #fefaf6;

  .homes {
    width: 1400px;
    margin-top: 12px;
  }
}

.home-content {
  min-height: 36px;
  border-radius: 0px 0px 0px 0px;
  box-sizing: border-box;
  padding: 24px;
}

.home-contentb {
  min-height: 36px;
  border-radius: 0px 0px 0px 0px;
  box-sizing: border-box;
  padding: 24px;
}

.home-contenta {
  border-radius: 4px;
  min-height: 36px;
  border-radius: 0px 0px 0px 0px;
  box-sizing: border-box;
}

.content-title {
  display: flex;
  position: relative;
}

.content-titles-more {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #888888;
  position: absolute;
  right: 0;
  top: 12px;
  cursor: pointer;
}

.tabItem {
  margin: 0 25px;
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .icon {
    position: absolute;
    right: -22px;
    font-size: 18px;
  }
}

.tabItem {
  cursor: pointer;
}

.tabItem:first-child {
  width: 104px;
  padding: 0;
  margin: 0 25px 0 0;
}

.active {
  border-bottom: 3px solid #c08c58;
  color: #c08c58;
  height: 53px;
  font-size: 18px;
  font-weight: bold;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

// .el-row {
//   margin-top: 25px;
// }

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.line-hidden {
  word-break: break-all; //在恰当的断字点进行换行
  overflow: hidden; //文字超出的进行隐藏
  text-overflow: ellipsis; //超出的文字用省略号表示
  display: -webkit-box; //将元素设为盒子伸缩模型显示 利用盒子模型
  -webkit-box-orient: vertical; //伸缩方向设为垂直方向
  -webkit-line-clamp: 2; //设定一共3行，超出的部分隐藏，并用省略号来表示
  color: #888888;
  margin: 12px 0;
}

.home-image {
  display: flex;
  justify-content: space-around;

  .home-image-text {
    font-size: 18px;
    color: #d2a04d;
  }

  .home-image-num {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: center;
  }
}

.rent-content {
  &-title {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
  }

  &-price {
    display: flex;

    justify-content: space-between;
    align-items: center;

    &1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #d2a04d;
      font-size: 18px;

      &1 {
        font-size: 14px;
      }
    }

    &2 {
      background: #c08c58;
      color: #ffffff;
      font-size: 14px;
      padding: 4px 12px;
      border-radius: 4px;
    }
  }

  .el-tag .is-closable {
    --el-tag-bg-color: var(--el-color-warning-light-9) !important;
  }
}

::v-deep .el-tag.is-closable {
  --el-tag-bg-color: var(--el-color-warning-light-9);
}

::v-deep .el-button {
  // --el-button-hover-bg-color: var(--el-color-warning-light-9) !important;
  background: #c08c58;
  color: white;
}

.screen {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgb(235, 236, 237);
}

.screen-content {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid rgb(235, 236, 237);
  position: relative;
  border-top: 0px;

  .screen-title {
    background: #fafafa;
    padding: 14px 24px;
    background: #f2f2f2;
    cursor: pointer;
  }

  .screen-item {
    margin: 0 10px;
    padding: 4px 10px;
    cursor: pointer;
  }
}

.screen-item-on,
.screen-item:hover {
  color: #fff;
  background: #c08c58;
  border-radius: 4px;
}

.screen-button {
  margin: 0 10px;
  background-color: #c08c58 !important;
  border-color: #fff !important;
}

.screen-button:hover {
  background-color: #c08c58 !important;
}

.screen-delete {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  color: #888888;
  font-size: 18px;
  cursor: pointer;
}

#container {
  display: flex;
  background-color: #ffffff;
  justify-content: space-around;
}

.date-item {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.dahezi {
  min-width: 1400px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("@/views/images/imgs_bg.png") no-repeat;
  background-size: 100% 800px;
}

// .xiaohezi {
//   width: 1400px;
//   min-width: 1400px;
//   padding: 30px 0 0 0;
// }
::v-deep .cell {
  font-size: 18px;
  font-weight: bold;
}

::v-deep .el-calendar-table .el-calendar-day {
  height: 60px;
}

::v-deep .el-calendar__body {
  padding: 0 20px;
}

::v-deep thead {
  background: #f4f4f4;
}

::v-deep .el-calendar-day:hover {
  background: none !important;
}

::v-deep .el-calendar__title {
  font-size: 20px;
}

::v-deep .el-button > span {
  font-size: 18px;
}

.riliList {
  display: flex;
}

.pagination {
  display: flex;
  justify-content: center;
}

:deep(.el-calendar__header) {
  border-bottom: 0 none;
}
.bidding-header {
  height: 64px;
  background: #B97F47;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  .cell-title {
    padding: 0 12px;
  }
  .cell-title:nth-child(1) {
    width: 12%;
  }
  .cell-title:nth-child(2) {
    width: 37%;
  }
  .cell-title:nth-child(3) {
    width: 8%;
  }
  .cell-title:nth-child(4) {
    width: 8%;
  }
  .cell-title:nth-child(5) {
    width: 10%;
  }
  .cell-title:nth-child(6) {
    width: 15%;
  }
  .cell-title:nth-child(7) {
    width: 10%;
  }

}
.bidding-item {
  height: 75px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #626066;
  .cell-value {
    padding: 0 12px;
    //font-family: "siyuanyahei";

  }
  .cell-value:nth-child(1) {
    width: 12%;
    color: #ff0000;
  }
  .cell-value:nth-child(2) {
    width: 37%;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }

  }
  .cell-value:nth-child(3) {
    width: 8%;
  }
  .cell-value:nth-child(4) {
    width: 8%;
  }
  .cell-value:nth-child(5) {
    width: 10%;
  }
  .cell-value:nth-child(6) {
    width: 15%;
    color: #ff0000;
  }
  .cell-value:nth-child(7) {
    width: 10%;
  }
}
.bidding-content-wrapper {
  height: 450px;
  overflow: hidden;
}
</style>
