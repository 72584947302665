<template>
  <footer class="footer" id="footer">
    <div class="footer-blogroll">
      <div class="footer-blogroll-title">友情链接</div>
      <div class="footer-blogroll-icon">
        <div class="footer-blogroll-icon-item shouzhi">
          <a href="https://paimai.caa123.org.cn/" target="_blank">
            <img src="@/assets/image/icon_logo1.png" alt />
            <div class="footer-nav-item bianse">中拍平台</div>
          </a>
        </div>
        <div class="footer-blogroll-icon-item shouzhi">
          <a href="https://zc-paimai.taobao.com/" target="_blank">
            <img src="@/assets/image/icon_logo2.png" alt />
            <div class="footer-nav-item bianse">阿里资产</div>
          </a>
        </div>
        <div class="footer-blogroll-icon-item shouzhi">
          <a href="https://paimai.caa123.org.cn/" target="_blank">
            <img src="@/assets/image/icon_logo1.png" alt />
            <div class="footer-nav-item bianse">中拍平台</div>
          </a>
        </div>
        <div class="footer-blogroll-icon-item shouzhi">
          <a href="https://zc-paimai.taobao.com/" target="_blank">
            <img src="@/assets/image/icon_logo2.png" alt />
            <div class="footer-nav-item bianse">阿里资产</div>
          </a>
        </div>
      </div>
      <!-- <div class="footer-nav">
        <div
          v-for="(item, index) in footerList"
          :key="index"
          class="footer-nav-item"
        >
          {{ item.name }}
        </div>
      </div>-->
      <div class="footer-nav" style="display: flex; justify-content: space-between">
        <div class="friendShip ">
          <div v-for="(item, index) in footerList2" :key="index" style="padding:6px 6px 6px 0;"
            class="footer-nav-item mouse qu">
          <a :href="item.link" target="_blank" v-if="item.link">{{ item.name }}</a>
          <span v-else>{{ item.name }}</span>
          </div>
        </div>

        <div class="footer-nav" style="align-self: flex-end; padding: 0">
          <!-- APP 小程序  二维码 -->
          <!-- <img src="@/assets/image/app_qr.png" alt="" /> -->
          <div class="footer-nav-item" style>
            <img src="@/assets/image/erweima.png" class="mt20 lw-150 lh-150" />
            <div class="tx-c mt5 fs14">
              四海易链
              <br />微信小程序
            </div>
          </div>
          <!-- <div class="footer-nav-item qr">
            <img src="@/assets/image/icon_registe.png" />
            <div>四海易链APP(IOS版）</div>
          </div>-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const footerList = [
  { name: "首页" },
  { name: "我要竞租" },
  { name: "公告公示" },
  { name: "竞价大厅" },
  { name: "咨询帮助" },
  { name: "关于我们" }
];
const footerList2 = [
  { name: "信息产业部ICP/IP地址信息备案序号：闽ICP备2020020263号-2",link:"https://beian.miit.gov.cn/#/Integrated/index" },
  { name: "公安备案：闽公网安备 35010302000752号",link:"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010302000752" },
  { name: "增值电信业务经营许可证：闽B2-20230923",link:require('@/assets/image/dianxin.png')},
  { name: "公司地址：福州市台江区鸡笼洲路2号福州城投榕发大厦4楼" },
  { name: "技术支持：四海易链技术团队" }
];
</script>

<style lang="scss" scoped>
.footer {
  min-width: 1400px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("@/views/images/imgs_bg.png") no-repeat;
  background-size: 100%;
  position: relative;
  z-index: 99;
  padding-bottom: 20px;
}

.footer-blogroll {
  width: 1400px;
  min-width: 1400px;

  &-title {
    font-size: 18px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: normal;
    color: #ffffff;
    padding-top: 14px;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0 20px;
    border-bottom: 1px solid #7185ae;

    .footer-blogroll-icon-item {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 42px;
          height: 42px;
          margin-right: 12px;
        }
      }

      a:hover {
        cursor: pointer;
      }
    }

    &-title {
      padding-top: 13px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      color: #ffffff;
      line-height: 28px;
      margin: 0 !important;
    }
  }

  .footer-nav {
    display: flex;
    align-items: center;

    .qr {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &-item {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }

    // &-item:hover {
    //   color: #d2a04d;
    // }
  }
}

.friendShip {
  flex: 2;
  display: flex;
  flex-wrap: wrap;

  .footer-nav-item {
    width: 50%;
    padding: 0 12px;
  }
}
a:focus{
  color: white!important;
  cursor: pointer;
}
a:hover // ,.footer-nav-item:hover

  {
    color: white!important;
  cursor: pointer;
}

.bianse:hover {
  color: #d2a04d;
}
</style>
