import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

import MyFooter from "@/components/MyFooter.vue"
import MyHeader from "@/components/MyHeader.vue"
import { useRouter} from 'vue-router'
import { onMounted, ref, provide, nextTick, watch  } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const isRouterAlive = ref(true)

let acIndex = ref(0)
 const reload=()=>{
  isRouterAlive.value=false
  nextTick(()=>{
    isRouterAlive.value=true
  })
 }
 const headEl:any = ref(null)
 const CApath:any = ref(true)
 const router = useRouter()
  watch(() => router.currentRoute.value,(router: any) => {
    if(router.path == '/sealNew' || router.path == '/bidsPrint'){
      CApath.value=false;
    }
		if (router.path == '/signup') {
			headEl.value.setActiveIndex(2)
		}
    else if(router.name=="Mydetails"){
      headEl.value.setActiveIndex(1)
    }
    else if(router.name=="CompetitionView"){
      headEl.value.setActiveIndex(1)
    }
    else if(router.path=="/Transactiondetails"){
      headEl.value.setActiveIndex(2)
    }
    else if(router.path=="/myPageHome"){
      headEl.value.setActiveIndex(null)
    }else if((router.path=="/")){
      headEl.value.setActiveIndex(0)
    }else if(router.path=="/biddingHall"){
      headEl.value.setActiveIndex(3)
    }else if(router.path=="/consultationhelp"){
      headEl.value.setActiveIndex(4)
    }else if(router.path=="/appoint"){
      headEl.value.setActiveIndex(5)
    }else if(router.path=="/about"){
      headEl.value.setActiveIndex(6)
    }
    else{
      headEl.value.setActiveIndex(null)
    }
  })
  provide('reload', reload)
	onMounted(() => {
		document.body.style.setProperty('--el-color-primary', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-1', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-2', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-3', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-4', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-5', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-6', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-7', '#C08C58');
		document.body.style.setProperty('--el-color-primary-light-8', '#C08C58');
		document.body.style.setProperty('--el-color-primary-dark-2', '#C08C58');
	})



return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("body", null, [
    (CApath.value)
      ? (_openBlock(), _createBlock(MyHeader, {
          key: 0,
          ref_key: "headEl",
          ref: headEl
        }, null, 512))
      : _createCommentVNode("", true),
    _createElementVNode("main", null, [
      (isRouterAlive.value)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    (CApath.value)
      ? (_openBlock(), _createBlock(MyFooter, {
          key: 1,
          ref: "layoutFooter"
        }, null, 512))
      : _createCommentVNode("", true)
  ]))
}
}

})