import request from '../util/request';


//查询标的内容模板
export const getmodule = (params: any) => {
    return request({
        url: '/rent/module/'+params.moduleId,
        method: 'get',
        params
    })
};


//提交新资产标的接口
export const add_assets = (data: any) => {
    return request({
        url: '/rent/assets',
        method: 'post',
        data
    })
}

//提交修改标的
export const add_bid = (data: any) => {
    return request({
        url: '/rent/bid',
        method: 'post',
        data
    })
}

//标的拒绝后修改
export const add_bid2 = (data: any) => {
    return request({
        url: '/rent/bid/trustAgain',
        method: 'put',
        data
    })
}


//确认标的
export const updateCustConfirmProgress = (data: any) => {
    return request({
        url: '/rent/bid/updateCustConfirmProgress',
        method: 'put',
        data
    })
}


//中标函确认
export const updateCustConfirm = (data: any) => {
    return request({
        url: '/rent/bid/updateCustConfirm/'+data.bidIds,
        method: 'put',
        data
    })
}


//中止申请
export const suspensionApply = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/suspensionApply',
        method: 'post',
        data
    })
}

//撤销终结申请
export const cancelSuspensionApply = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/cancelSuspensionApply',
        method: 'post',
        data
    })
}


//终结申请
export const endApply = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/endApply',
        method: 'post',
        data
    })
}

//撤销终结
export const cancelEndApply = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/cancelEndApply',
        method: 'post',
        data
    })
}

//重新发起标的流程
export const bidBackApply = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/bidBackApply',
        method: 'post',
        data
    })
}

//获取模版
export const getBidMouldAttachmentList = (params: any) => {
    return request({
        url: '/rent/sysAttachment/getBidMouldAttachmentListByCustType',
        method: 'get',
        params
    })
}

//线下签署合同
export const updateContract = (data: any) => {
    return request({
        url: '/rent/bid/updateBidContract',
        method: 'put',
        data
    })
}


//标的驳回
export const custBackAduit = (data: any) => {
    return request({
        url: '/rent/bid/custBackAduit',
        method: 'put',
        data
    })
}

//延长期
export const applyForOvertime = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/applyForOvertime',
        method: 'post',
        data
    })
}

// //中止申请
// export const suspensionApply = (data: any) => {
//     return request({
//         url: '/rent/bidAuditrecord/suspensionApply',
//         method: 'post',
//         data
//     })
// }

//门户全部异常流程
export const bidAllFlowPath = (params: any) => {
    return request({
        url: '/rent/bidAuditrecord/bidAllFlowPath',
        method: 'get',
        params
    })
};

//重新招标申请
export const anewInvitationForBidsApplyFor = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/anewInvitationForBidsApplyFor',
        method: 'post',
        data
    })
}

//项目变更申请
export const projectChangeApplyFor = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/projectChangeApplyFor',
        method: 'post',
        data
    })
}

//项目变更出租方公告确认
export const alterationNotarize = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/alterationNotarize',
        method: 'post',
        data
    })
}

//项目变更出租方公告驳回
export const alterationTurnDown = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/alterationTurnDown',
        method: 'post',
        data
    })
}

//新公告详情
export const changeNoticeDetails = (data: any) => {
    return request({
        url: '/rent/bidAuditrecord/changeNoticeDetails',
        method: 'post',
        data
    })
}


// 标的图片上传
export const uploadFiles = (data: any) => {
    return request({
        url: '/file/upload',
        method: 'post',
        data
    })
}


//暂存
export const temporaryFlowAssets = (data: any) => {
    return request({
        url: '/rent/assets/temporaryFlowAssets',
        method: 'post',
        data
    })
}


//获取公告模板
export const getTopRentNotice = (params: any) => {
    return request({
        url: '/rent/module/getTopRentNotice',
        method: 'get',
        params
    })
};

//到出模板优先人
export const export_youxian = (data: any) => {
    return request({
        url: '/rent/priority/export',
        method: 'post',
        data
    })
};

//到出模板黑名单
export const export_heimingdan = (data: any) => {
    return request({
        url: '/rent/blacklist/export',
        method: 'post',
        data
    })
};

//添加资产
export const insertAssets = (data: any) => {
    return request({
        url: '/rent/assets/insertAssets',
        method: 'post',
        data
    })
};

//查询资产信息
export const getAssetsByAssetsIds = (params: any) => {
    return request({
        url: '/rent/assets/getAssetsByAssetsIds/'+params.assetsIds,
        method: 'get',
        params
    })
}

//资产选择
export const getAssetsSelect = (params: any) => {
    return request({
        url: '/rent/assets/getAssetsSelect',
        method: 'get',
        params
    })
}

//修改资产
export const editAssets = (data: any) => {
    return request({
        url: '/rent/assets/editAssets',
        method: 'put',
        data
    })
}

//根据Type获取地区
export const getAreaByType = (params: any) => {
    return request({
        url: '/rent/area/getAreaByType/'+params.areaType,
        method: 'get',
        params
    })
}

//根据ID获取该地区下级
export const getSubordinateAreaById = (params: any) => {
    return request({
        url: '/rent/area/getSubordinateAreaById/'+params.areaId,
        method: 'get',
        params
    })
}

//根据标的查询成交人信息
export const getWinEntryVoByBidlds = (params: any) => {
    return request({
        url: '/rent/entry/getWinEntryVoByBidIds/'+params.bidIds,
        method: 'get',
    })
}

// 获取标的列表
export const getWinNoticeBidVoPageList = (params: any) => {
    return request({
        url: '/rent/bid/getWinNoticeBidVoPageList/front',
        method: 'get',
        params
    })
}

// 获取标的最新内容模板
export const getModuleLast = (moduleId: number) => {
    return request({
        url: '/rent/module/last/' + moduleId,
        method: 'get'
    })
}

// 根据标的ID获取标的信息
export const createNoticeContent = (data: { bidIds: number; moduleId: number; }) => {
    return request({
        url: '/rent/notice/createNoticeContent',
        method: 'post',
        data
    })
}
