<template>
  <div class="filterBar">
    <div class="item" v-for="(item, index) in options.filterData" :key="index">
      <div class="label">
        {{ item.name }}
      </div>
      <div class="info">
        <span v-for="(subItem, subIndex) in item.dictData" :class="{ checked: subItem.checked }" :key="subIndex"
          @click="checkItem(item, subItem, subIndex)">
          {{ subItem.dictName }}
        </span>
        <!-- 区间组件 -->
        <div class="numberRange" v-if="item.type === 'bidPriceQuery'">
          <!-- <div class="input">
            <el-input-number style="width: 130px; margin: 0 0px; height: 32px" :controls="false" v-model="options.min" />
          </div>
          <div class="symbol">—</div>
          <div class="input" style="margin-right: 15px">
            <el-input-number style="width: 130px; margin: 0 0px; height: 32px" :controls="false" v-model="options.max" />
          </div> -->
          <!-- <el-button type="primary" @click="subPrice(index)">确认</el-button> -->
          <div class="box">
            <div class="input-box">
              <div class="input">
                <el-input-number style="width: 80px; margin: 0 0px; height: 32px" :controls="false"
                  v-model="options.min" />
              </div>
              <div class="symbol">-</div>
              <div class="input" style="margin-right: 15px">
                <el-input-number style="width: 80px; margin: 0 0px; height: 32px" :controls="false"
                  v-model="options.max" />
              </div>
            </div>
            <div class="button-box">
              <span @click="empty(index)" class="clear">清空</span>
              <el-button type="primary" @click="subPrice(index)">确认</el-button>
            </div>
          </div>
        </div>
        <!-- 时间组件 -->
        <div class="dateRange" v-if="item.type === 'bidTimeQuery'">
          <!-- <el-date-picker format="YYYY-MM-DD" v-model="options.date" style="margin-right: 15px" type="datetimerange"
            range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" @focus="subDate(item, index)"
            @change="subDate(item, index)" /> -->
          <el-date-picker v-model="options.date" style="margin-right: 15px" type="daterange" range-separator="-"
            start-placeholder="开始时间" end-placeholder="结束时间" @focus="subDate(item, index)"
            @change="subDate(item, index)" />
          <!-- <el-button type="primary" @click="subDate(item, index)">确认</el-button> -->
        </div>
      </div>
    </div>
    <div class="item" v-for="(item, index) in options.filterData" :key="index" v-show="item.type === 'noticeType'">
      <div class="label">公告信息</div>
      <div class="info">
        <div class="input" style="margin-right: 15px">
          <!-- text21 -->
          <!-- <span class="c-242" style="margin-left: 0px">公告名称</span> -->
          <el-input style="width: 200px; margin: 0 0 0 10px; height: 32px" :controls="false" v-model="options.text"
            placeholder="公告名称/编号" />
        </div>
        <el-button type="primary" @click="subtext(index)">确认</el-button>
      </div>
    </div>
    <div class="item" v-for="(item, index) in options.filterData" :key="index" v-show="item.type === 'bidPriceQuery'">
      <div class="label">标的信息</div>
      <div class="info">
        <div class="input" style="margin-right: 15px">
          <el-input style="width: 200px; margin: 0 0 0 10px; height: 32px" :controls="false" v-model="options.text"
            placeholder="标的名称/编号" />
        </div>
        <el-button type="primary" @click="quantext(index)">确认</el-button>
      </div>
    </div>
    <div class="item">
      <div class="label">已选条件</div>
      <div class="info">
        <el-tag v-for="(item, index) in checkList" :key="index" class="mx-1" closable :disable-transitions="false"
          @close="handleClose(item, index)">
          {{ item.dictName }}
        </el-tag>
        <el-button v-if="checkList.length" type="primary" @click="clear(index)">一键清空</el-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, watch, computed } from "vue";
import { getSubordinateAreaById } from "@/api/myhire";
const props = defineProps<{ dataList: [] }>();
console.log("父传子", props);
const emit = defineEmits(["change", "clearAll"]);
const options: any = reactive({
  min: null,
  max: null,
  date: [],
  filterData: props.dataList,
  queryParams: {},
});
watch(
  () => props.dataList,
  () => {
    options.filterData = props.dataList;
  }
);
const checkList = computed(() => {
  let aList: any = [];
  options.filterData.forEach((e: any, i: any) => {
    if (e.dictData.length) {
      e.dictData.forEach((v: any, m: any) => {
        if (v.checked && v.dictValue != "-1") {
          aList.push({
            ...v,
            index: i,
            subIndex: m,
            type: e.type,
          });
        }
      });
    }
  });
  return aList;
});
const createQueryParams = () => {
  let arr: any = options.filterData;
  let obj: any = {};
  arr.forEach((e: any) => {
    obj[e.type] = [];
    if (e.dictData.length) {
      e.dictData.forEach((v: any) => {
        if (v.checked && v.dictValue != -1) {
          obj[e.type].push(v.dictValue);
        }
      });
    }
  });
  obj.bidStartPrice = options.min;
  obj.bidEndPrice = options.max;
  obj.bidCodeOrName = options.text;
  if (options.date instanceof Array) {
    if (options.date.length >= 1) {
      obj.beginBidTime = options.date[0];
      obj.endBidTime = options.date[1];
    }
  }
  return obj;
};
const clear = () => {
  options.date = [];
  options.min = "";
  options.max = "";
  options.text = "";
  emit("clearAll");
  setTimeout(() => {
    emit("change", createQueryParams());
  }, 800);
};
const handleClose = (item: any) => {
  let index: any = item.index;
  let subIndex: any = item.subIndex;
  options.filterData[index]["dictData"][subIndex].checked = false;
  let nLen = options.filterData[index]["dictData"].length;
  options.filterData[index]["dictData"].forEach((e: any) => {
    if (!e.checked) {
      nLen--;
    }
  });
  if (!nLen) {
    options.filterData[index]["dictData"][0].checked = true;
  }
  emit("change", createQueryParams());
};

const subtext = (index: any) => {
  emit("change", createQueryParams());
};
const quantext = (index: any) => {
  emit("change", createQueryParams());
};

const subPrice = (index: any) => {
  options.filterData[index].dictData.forEach((e: any) => {
    e.checked = false;
  });
  emit("change", createQueryParams());
};
const empty = (index: any) => {
  options.filterData[index].dictData.forEach((e: any) => {
    options.min = "";
    options.max = "";
  });
  options.filterData[index].dictData.forEach((e: any) => {
    e.checked = false;
  });
  options.filterData[index].dictData[0].checked = true;
  emit("change", createQueryParams());
};

const subDate = (item: any, index: any) => {
  console.log(options.date)
  if (options.date == null || Object.keys(options.date).length == 0) {
    options.date = [];
    options.filterData[index].dictData.forEach((e: any) => {
      e.checked = false;
    });
    options.filterData[index].dictData[0].checked = true;
  }
  else {
    options.filterData[index].dictData.forEach((e: any) => {
      e.checked = false;
    });
  }
  emit("change", createQueryParams());
};

const checkItem = (item: any, subItem: any, subIndex: any) => {
  // console.log("========", item, subItem, subIndex);
  console.log(subItem.dictValue)

  if (item.type == 'cityArray') {
    if (subItem.dictValue == -1) {
      let dictData = [];
      dictData.unshift({
        dictValue: -1,
        dictName: '全部',
        checked: true
      })
      options.filterData[2].dictData = dictData;
    } else {
      getSubordinateAreaById({
        areaId: subItem.dictValue
      }).then(res => {
        if (res.code == 200) {
          let result = res.data;
          let dictData = [];
          console.log('result', result)
          for (let i = 0; i < result.length; i++) {
            dictData.push({
              dictValue: result[i].areaId,
              dictName: result[i].areaName
            })
          }
          dictData.unshift({
            dictValue: -1,
            dictName: '全部',
            checked: true
          })
          options.filterData[2].dictData = dictData;
        }
      })
    }
  }
  if (subIndex !== 0) {
    if (item.type === "bidTimeQuery" || item.type === "bidPriceQuery" || item.type == "cityArray") {
      options.min = null;
      options.max = null;
      item.dictData.forEach((v: any) => {
        v.checked = false;
      });
      item.dictData[subIndex].checked = true;
    } else {
      item.dictData[0].checked = false;
      subItem.checked = !subItem.checked;
      console.log('aaaa', item)
      const hasFalse = item.dictData.every((obj:any) => obj.checked == false);
      console.log(hasFalse)
      if(hasFalse){
        item.dictData[0].checked = true;
      }
    }
    if (item.type === "bidPriceQuery") {
      options.min = null;
      options.max = null;
    }
    if (item.type === "bidTimeQuery") {
      options.date = [];
    }
  } else {
    item.dictData.forEach((e: any) => {
      e.checked = false;
    });
    item.dictData[0].checked = true;
  }
  emit("change", createQueryParams());
};
</script>

<style lang="scss" scoped>
.filterBar {
  border: 1px solid #ebeced;
  display: flex;
  flex-direction: column;
  margin: 24px;

  .item {
    display: flex;
    height: 48px;
    border-bottom: 1px solid #ebeced;
    background-color: #fff;

    .label {
      background-color: #f2f2f2;
      border-right: 1px solid #ebeced;
      color: #333333;
      width: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      height: 48px;
      position: relative;

      span {
        padding: 6px 12px;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 6px;

        &.checked {
          background-color: #c08c58;
          color: #fff;
        }
      }

      .box {
        position: absolute;
        top: 0;
        padding: 10px;
        width: 206px;
        height: 100px;

        .input-box {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .button-box {
          display: none;

          .clear {
            color: #999;
          }
        }

        &:hover {
          background-color: #fff;
          border: 1px solid #ebeced;
          box-shadow: 2px 3px 12px rgb(0 0 0 / 8%);

          .button-box {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
          }
        }
      }
    }
  }
}

.numberRange {
  display: flex;
  align-items: center;

  .input {
    width: 120px;
  }

  .symbol {
    margin: 5px 10px 0 10px;
  }
}

.dateRange {
  display: flex;
  align-items: center;
}
</style>
