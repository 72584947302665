<template>
  <!-- 首页广告位 -->
  <div class="bannerIndex banner">
    <el-carousel :interval="3000" arrow="always">
      <el-carousel-item
        v-for="item in datas.banners"
        :key="item.id"
        @click="routDetail('Mydetails', item.jumpUrl)"
      >
        <div class="bannerItem shouzhi">
          <el-image :src="item.url" fit="cover" />
        </div>
      </el-carousel-item>
      <div v-show="true">
        <div
          class="banner-buttom1 shouzhi"
          @click.stop="routclick('CompetitionView')"
        >
          我要竞租
        </div>
        <div class="banner-buttom2 shouzhi" @click.stop="routclick('login')">
          我要出租
        </div>
      </div>
    </el-carousel>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { showAdvertising } from "@/api/home";
let route = useRoute();
let router = useRouter();
let datas: any = reactive({
  loading: false,
  banners: [
    {
      id: 0,
      url: require("../assets/image/img_bannerbg.png"),
      operates: true,
    },
  ],
});

// 路由跳转
const routclick = (res: number | string, id?: any) => {
  let url = router.resolve(`/${res}`);
  window.open(url.href);
};

const routDetail = (res: number | string, url?: any) => {
  if (url) {
    // let url = router.resolve({
    //   path: `/${res}`,
    //   query: {
    //     bidIds: id,
    //   },
    // });
    window.open(url);
  } else {
    let urls = router.resolve({
      path: `/`,
    });
    window.open(urls.href);
  }
};

onMounted(() => {
  showAdvertising({}).then((res) => {
    if (res.code == 200) {
      datas.banners = res.data;
    }
  });
});
</script>

<style lang="scss" scoped>
.bannerItem {
  width: 100%;
  height: 100%;
  .el-image,
  .el-image__wrapper {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.banner {
  min-width: 1400px;
  position: relative;
  z-index: 0;
  color: #764810;

  &-buttom1 {
    display: flex;
    align-items: center;
    padding: 11px 24px;
    background: linear-gradient(180deg, #ffffff 0%, #efdbab 100%);
    border-radius: 4px 4px 4px 4px;
    position: absolute;
    bottom: 80px;
    left: 17%;
    font-size: 20px;
  }

  &-buttom2 {
    display: flex;
    align-items: center;
    padding: 11px 24px;
    background: linear-gradient(180deg, #ffffff 0%, #efdbab 100%);
    border-radius: 4px 4px 4px 4px;
    position: absolute;
    bottom: 80px;
    left: calc(17% + 148px);
    font-size: 20px;
  }
}
</style>
